
import { useEffect, useState } from 'react';
import { authenticationStore } from '../../services/store';
import User from '../../models/User';

export default function useCurrentUser() {
  const [currentUser, setCurrentUser] = useState<User | null>(authenticationStore.get('user') as User);

  useEffect(()=>{
    authenticationStore.on('user', setCurrentUser);
    
    return ()=>{
      authenticationStore.off('user', setCurrentUser);
    }
  }, []);

  return currentUser;
}