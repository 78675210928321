
import Product from './Product';
import Location from './Location';
import Bin from './Bin';
import Vendor from './Vendor';

export default class ShoppingList {
  id: number;
  quantity: number;
  bin_id: number;
  bin: Bin;
  user_id: number;
  activity_id: number;
  product_id: number;
  product: Product;
  vendor_id: number;
  vendor: Vendor;
  location_id: number;
  location: Location;
  organization_id: number;
  created_at: string;

  constructor(data: {[key: string]: any}) {
    data = data || {};

    this.id = data.id;
    this.quantity = data.quantity || 0;
    this.bin_id = data.bin_id;
    this.bin = new Bin(data.bin);
    this.user_id = data.user_id;
    this.activity_id = data.activity_id;
    this.product_id = data.product_id;
    this.product = new Product(data.product);
    this.vendor_id = data.vendor_id;
    this.vendor = new Vendor(data.vendor);
    this.location_id = data.location_id;
    this.location = new Location(data.location);
    this.organization_id = data.organization_id;
    this.created_at = data.created_at;
  }
}
