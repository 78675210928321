
import { useState, useEffect, useCallback } from 'react';
import { binsService } from '../../services/api';
import Bin from '../../models/Bin';
import { useNotifier } from '../../providers/NotifierProvider';

export default function useBin(binId: number): [Bin|null, boolean, string] {
  const [bin, setBin] = useState<Bin | null>(null);
  const [loading, setLoading] = useState(false);
  const [message, setMessage] = useState('');
  const notify = useNotifier();

  const fetchBin = useCallback(async ()=>{
    try{
      setLoading(true);
      const bin = await binsService.fetchById(binId);
      setBin(bin);
      setMessage('');
    }
    catch(err){
      setMessage('Failed to fetch bin.');
      notify(err);
    }
    finally{
      setLoading(false);
    }
  }, [binId, notify]);

  useEffect(()=>{
    fetchBin();
  }, [fetchBin]);

  return [bin, loading, message];
}
