
import React, { useRef } from 'react';
import { vendorsService } from '../../services/api';
import useVendors from '../../hooks/use-vendors';
import { useNotifier } from '../../providers/NotifierProvider';
import VendorDialog, { VendorDialogRef, VendorData } from '../../dialogs/VendorDialog';
import Container from '@material-ui/core/Container';
import Box from '@material-ui/core/Box';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';
import Fab from '@material-ui/core/Fab';
import LinearProgress from '@material-ui/core/LinearProgress';
import InfoMessage from '../../components/InfoMessage';
import VendorCard from './VendorCard';
import AddIcon from '@material-ui/icons/Add';

const VendorsPage: React.FC = function() {
  const [vendors, loading, message] = useVendors();
  const vendorDialog = useRef<VendorDialogRef>(null);
  const notify = useNotifier();

  async function handleVendorCreate(data: VendorData) {
    await vendorsService.create(data);
    notify('Vendor created.');
  }

  return (
    <Container maxWidth={false}>
      <Box py={2}>
        <Paper>
          <Box p={2}>
            <Grid container spacing={2} justify="space-between" alignItems="center">
              <Grid item>
                <Typography variant="h6">Vendors</Typography>
                <Typography variant="body2" color="textSecondary">Add/Update vendors</Typography>
              </Grid>
              <Grid item>
                <Fab
                  size="medium"
                  color="primary"
                  onClick={()=>vendorDialog.current?.openDialog(true)}
                >
                  <AddIcon />
                </Fab>
              </Grid>
            </Grid>
          </Box>

          {!loading && !!message && (
            <Box p={2}>
              <InfoMessage>{message}</InfoMessage>
            </Box>
          )}
        </Paper>

        {loading && (
          <LinearProgress />
        )}

        <Box py={2}>
          <Grid container spacing={1}>
            {vendors.map(vendor=>(
              <Grid key={''+vendor.id} item xs={12} sm={4} md={3} xl={2}>
                <VendorCard vendor={vendor} />
              </Grid>
            ))}
          </Grid>
        </Box>
      </Box>

      <VendorDialog
        ref={vendorDialog}
        primaryActionText="Create"
        secondaryActionText="Cancel"
        primaryAction={handleVendorCreate}
      />
    </Container>
  );
}

export default VendorsPage;
