
import React, { useCallback, useRef } from 'react';
import ConfirmDialogContext from './ConfirmDialogContext';
import ConfirmDialog, { ConfirmDialogRef } from '../../components/ConfirmDialog';

const ConfirmDialogProvider: React.FC = function(props) {
  const dialogRef = useRef<ConfirmDialogRef>(null);
  const confirm = useCallback((title: string, message: string)=>{
    if(dialogRef.current)
      return dialogRef.current.confirm(title, message);
    else
      return Promise.resolve(false);
  }, []);

  return (
    <ConfirmDialogContext.Provider value={confirm}>
      {props.children}
      <ConfirmDialog ref={dialogRef}/>
    </ConfirmDialogContext.Provider>
  );
};

export default ConfirmDialogProvider;
