
import apiClient from '../api-client';
import EventEmitter from 'events';

export default class ApiService<T> extends EventEmitter {
  protected resourcePath: ()=>string;

  constructor(
    resourcePath: string | (()=>string),
    protected resourceName: string = 'resource',
    protected deserialize: (data: any)=>T = (data: any)=>data
  ){
    super();
    if(typeof resourcePath === 'string')
      this.resourcePath = ()=>resourcePath;
    else
      this.resourcePath = resourcePath;
  }

  async create<S>(data: S) {
    const url = this.resourcePath();
    const res = await apiClient.post(url, data);
    const createdData = this.deserialize(res.data);

    this.emit(`${this.resourceName}-created`, createdData);
    return createdData;
  }

  async fetchAll(): Promise<T[]> {
    const url = this.resourcePath();
    const res = await apiClient.get(url);
    return res.data.map(this.deserialize);
  }

  async fetchById(id: number): Promise<T> {
    const url = `${this.resourcePath()}/${id}`;
    const res = await apiClient.get(url);
    return this.deserialize(res.data);
  }

  async updateById<S>(id: number, data: S): Promise<T> {
    const url = `${this.resourcePath()}/${id}`;
    const res = await apiClient.put(url, data);
    const updatedData = this.deserialize(res.data);

    this.emit(`${this.resourceName}-updated`, updatedData, id);
    return updatedData;
  }

  async deleteById(id: number): Promise<boolean> {
    const url = `${this.resourcePath()}/${id}`;
    await apiClient.delete(url);

    this.emit(`${this.resourceName}-deleted`, id);
    return true;
  }
}