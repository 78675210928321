
import axios from 'axios';

// const FIREBASE_GET_URL = process.env.REACT_APP_FIREBASE_GET_URL;
// const FIREBASE_BASE_URL = process.env.REACT_APP_FIREBASE_BASE_URL;
const FIREBASE_BASE_URL_US_CENTRAL = process.env.REACT_APP_FIREBASE_BASE_URL_US_CENTRAL;

const firebaseClient = axios.create({
  baseURL: FIREBASE_BASE_URL_US_CENTRAL
});

export default firebaseClient;
