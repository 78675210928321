
import React, { useState, useRef } from 'react';
import { Theme } from '@material-ui/core/styles';
import { AuthenticationControlProps } from './types';
import { Link as RouterLink } from 'react-router-dom';
import { useMediaQuery } from '@material-ui/core';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import Link from '@material-ui/core/Link';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import Avatar from '@material-ui/core/Avatar';

const AuthenticationControl: React.FC<AuthenticationControlProps> = function(props) {
  const {
    authenticated,
    user,
    onLogout = ()=>null,
    buttonLabel = 'Login',
    pathToLogin = '/login'

  } = props;

  const avatarButton = useRef<HTMLButtonElement>(null);
  const [menuOpen, setMenuOpen] = useState(false);
  const isSmallScreen = useMediaQuery((theme: Theme)=>theme.breakpoints.down('sm'), { noSsr: true });

  if(!authenticated){
    return (
      <Link
        underline="none"
        to={pathToLogin}
        component={RouterLink}
      >
        <Button
          variant="outlined"
          color="secondary"
        >
          { buttonLabel }
        </Button>
      </Link>
    );
  }
  else{
    return (
      <Grid container alignItems="center" spacing={1}>
        <Grid item>
          <Link
            ref={avatarButton}
            component="button"
            onClick={()=>setMenuOpen(true)}
          >
            <Avatar />
          </Link>
          <Menu
            variant="menu"
            open={menuOpen}
            anchorEl={avatarButton.current}
            onClose={()=>setMenuOpen(false)}
          >
            <MenuItem button onClick={onLogout}>Logout!</MenuItem>
          </Menu>
        </Grid>
        {!!user && !isSmallScreen && (
          <Grid item>
            <Typography>{user.first_name} {user.last_name}</Typography>
            <Typography variant="subtitle2" color="textSecondary">{user.email}</Typography>
          </Grid>
        )}
      </Grid>
    );
  }
};

export default AuthenticationControl;
