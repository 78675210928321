
import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { InfoMessageProps, InfoMessageColors } from './types';
import Typography from '@material-ui/core/Typography';
import InfoIcon from '@material-ui/icons/Info';
import WarningIcon from '@material-ui/icons/Warning';
import ErrorIcon from '@material-ui/icons/Error';
import DoneIcon from '@material-ui/icons/Done';
import styles from './styles';

const useStyles = makeStyles(styles);

function getIcon(textColor: InfoMessageColors | undefined): any {
  switch(textColor){
    case 'warning': return WarningIcon;
    case 'error': return ErrorIcon;
    case 'success': return DoneIcon;
    case 'info':
    default: return InfoIcon;
  }
}

const InfoMessage: React.FC<InfoMessageProps> = function(props) {
  const {
    children,
    textColor,
    noIcon,
    Icon = getIcon(textColor),
    ...otherProps

  } = props;

  const classes = useStyles(props);

  return (
    <Typography className={classes.root} {...otherProps}>
      {!noIcon && <Icon className={classes.icon} />} {children}
    </Typography>
  );
};

export default InfoMessage;
