
import firebase from './firebase-client';

export type DocumentId = string | null | undefined;

export function cleanId(id: DocumentId): DocumentId {
  if(!id)
    return null;

  if(!id.trim().length)
    return null;

  return id.includes('/') ? id.split("/")[1] : id;
}

export function getIdInfo(id: DocumentId): any {
  id = cleanId(id);

  if(!id)
    return null;

  let info;

  switch(id.length){
    case 9: info = {id, type: 'activity', title: 'Activity', article: 'an' }; break;
    case 11: info = {id, type: 'location', title: 'Baecon', article: 'a' }; break;
    case 17: info = {id, type: 'access_rights', title: 'User', article: 'a' }; break;
    case 25: info = {id, type: 'licence_plate', title: 'Licence Plate', article: 'a' }; break;
    case 32: info = {id, type: 'document', title: 'Document', article: 'a' }; break;
    case 36: info = {id, type: 'transaction', title: 'Transaction', article: 'a' }; break;
    default: info = null; break;
  }

  return info;
}

export async function getById(id: string) {
  let info;

  if(!(info = getIdInfo(id)))
    throw new Error('Invalid id.');
    
  const response = await firebase.post('/firebaseGet', {
    path: `${info.type}/${cleanId(id)}`
  });

  if(!response.data)
    throw new Error('Nothing could be found.');

  return response.data;
}
