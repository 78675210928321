
import React, { forwardRef, useState, useImperativeHandle, useCallback } from 'react';
import { ConfirmDialogProps, ConfirmDialogRef } from './types';
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogActions from '@material-ui/core/DialogActions';
import Button from '@material-ui/core/Button';

const ConfirmDialog: React.ForwardRefRenderFunction<ConfirmDialogRef, ConfirmDialogProps> = function(props, ref) {
  const [open, setOpen] = useState(false);
  const [title, setTitle] = useState('');
  const [message, setMessage] = useState('');
  const [resolve, setResolve] = useState<((a: boolean)=>void) | null>(null);

  const handleDialogClose = useCallback((result: boolean)=>{
    resolve && resolve(result);
    setOpen(false);

  }, [resolve]);

  const confirm = useCallback((title: string, message: string)=>{
    setTitle(title);
    setMessage(message);
    setOpen(true);
    return new Promise<boolean>((resolve, reject)=>{
      setResolve(()=>resolve);
    });

  }, []);

  useImperativeHandle(ref, ()=>({ confirm }), [confirm]);

  return (
    <Dialog
      open={open}
      onClose={()=>handleDialogClose(false)}
      fullWidth
      maxWidth="xs"
    >
      <DialogTitle>{ title }</DialogTitle>
      <DialogContent>
        <DialogContentText>{ message }</DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button
          size="small"
          onClick={()=>handleDialogClose(false)}
        >
          Cancel
        </Button>

        <Button
          size="small"
          onClick={()=>handleDialogClose(true)}
          color="primary"
          variant="contained"
        >
          Ok
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default forwardRef(ConfirmDialog);
