
import React, { useState } from 'react';
import { usersService } from '../../services/api';
import { UserRowProps } from './types';
import { useConfirmDialog } from '../../providers/ConfirmDialogProvider';
import { useNotifier } from '../../providers/NotifierProvider';
import Grid from '@material-ui/core/Grid';
import TableRow from '@material-ui/core/TableRow';
import TableCell from '@material-ui/core/TableCell';
import IconButton from '@material-ui/core/IconButton';
import EditIcon from '@material-ui/icons/Edit';
import DeleteIcon from '@material-ui/icons/Delete';
import UserEditDialog from '../../dialogs/UserEditDialog';

const UserRow: React.FC<UserRowProps> = function(props) {
  const { user } = props;
  const [openUserEditDialog, setOpenUserEditDialog] = useState(false);
  const [loading, setLoading] = useState(false);
  const confirm = useConfirmDialog();
  const notify = useNotifier();

  async function handleDelete() {
    try{
      if(await confirm('Are you sure?', 'Deleting user will completely remove them from the system. This action cannot be undone.')){
        setLoading(true);
        await usersService.deleteById(user.id);
      }
    }
    catch(err){
      setLoading(false);
      notify(err);
    }
  }

  return (
    <TableRow>
      <TableCell>{ user.person_id }</TableCell>
      <TableCell>{ user.get('full_name') }</TableCell>
      <TableCell>{ user.email }</TableCell>
      <TableCell>
        <Grid container spacing={1} justify="flex-end">
          <Grid item>
            <IconButton
              size="small"
              onClick={handleDelete}
              disabled={loading}
            >
              <DeleteIcon color="error" />
            </IconButton>
          </Grid>
          <Grid item>
            <IconButton
              size="small"
              disabled={loading}
              onClick={()=>setOpenUserEditDialog(true)}
            >
              <EditIcon color="action" />
            </IconButton>

            <UserEditDialog
              open={openUserEditDialog}
              onClose={()=>setOpenUserEditDialog(false)}
              user={user}
            />
          </Grid>
        </Grid>
      </TableCell>
    </TableRow>
  );
};

export default UserRow;
