
import React, { useState } from 'react';
import { shoppingListsService } from '../../services/shopping-lists';
import useShoppingLists from '../../hooks/use-shopping-lists';
import { Theme } from '@material-ui/core/styles';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import ShoppingListDataGrid from './ShoppingListDataGrid';
import Container from '@material-ui/core/Container';
import Box from '@material-ui/core/Box';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import LinearProgress from '@material-ui/core/LinearProgress';
import InfoMessage from '../../components/InfoMessage';
import DownloadIcon from '@material-ui/icons/GetApp';
import ShoppingListAccordian from './ShoppingListAccordian';

const ShoppingListPage: React.FC = function() {
  const [shoppingLists, loading, message] = useShoppingLists();
  const isSmallScreen = useMediaQuery((theme: Theme)=>theme.breakpoints.down('sm'), { noSsr: true });
  const [displayType, setDisplayType] = useState(isSmallScreen ? 'cards' : 'data-grid');

  function generateCsv() {
    shoppingListsService.downloadCsv(shoppingLists);
  }

  return (
    <Container maxWidth={false}>
      <Box py={2}>
        {loading && (
          <LinearProgress />
        )}
        <Paper>
          <Box p={2}>
            <Grid container alignItems="center" justify="space-between">
              <Grid item>
                <Typography variant="h6">Shopping list</Typography>
                <Typography variant="body2" color="textSecondary">{loading ? '...' : `${shoppingLists.length} items in the list.`}</Typography>
              </Grid>

              {!!shoppingLists.length && isSmallScreen && (
                <Grid item>
                  <Button
                    onClick={generateCsv}
                    startIcon={<DownloadIcon />}
                  >CSV</Button>
                </Grid>
              )}
            </Grid>
          </Box>

          {!loading && !!message && (
            <Box p={2}>
              <InfoMessage>{ message }</InfoMessage>
            </Box>
          )}
        </Paper>
      </Box>

      <Box mb={2}>
        <Grid container>
          {displayType === 'cards' && (
            <Grid item xs={12} md={6}>
              {shoppingLists.map(shoppingList=>(
                <ShoppingListAccordian
                  key={''+shoppingList.id}
                  shoppingList={shoppingList}
                />
              ))}
            </Grid>
          )}

          {displayType === 'data-grid' && (
            <Grid item xs={12}>
              <Paper>
                <ShoppingListDataGrid
                  data={shoppingLists}
                  containerHeight={700}
                />
              </Paper>
            </Grid>
          )}
        </Grid>
      </Box>
    </Container>
  );
};

export default ShoppingListPage;
