
import React, { useCallback, useState, useImperativeHandle, forwardRef, useRef, useEffect } from "react";
import QrReader from "react-qr-reader";
import { QrCodeScannerProps, QrCodeScannerRef } from './types';
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogActions from "@material-ui/core/DialogActions";
import Button from "@material-ui/core/Button";
import ScannerIcon from '@material-ui/icons/CropFree';
import ImageIcon from '@material-ui/icons/Image';

const QrCodeScanner: React.ForwardRefRenderFunction<QrCodeScannerRef, QrCodeScannerProps> = function(props, ref) {
  const {
    title = 'Scan QR code',
    facingMode = "environment",
    onResult = () => null,
    buttonText = "Scan QR",
    disabled = false,
    hidden,
    ButtonProps = {}

  } = props;

  const qrReaderRef = useRef<QrReader>(null);
  const userAgent = window.navigator.userAgent;
  const [open, setOpen] = useState(false);
  const [nativeScannerOpen, setNativeScannerOpen] = useState(false);
  const [legacyMode, setLegacyMode] = React.useState(false);
  const [message, setMessage] = React.useState("Loading...");

  const handleDialogClose = useCallback(()=>{
    setMessage("");
    setOpen(false);
    setNativeScannerOpen(false);

  }, []);

  const handleScanResult = useCallback(result=>{
    if (!result)
      return setMessage("No QR detected.");

    onResult(result);
    
    if(open)
      handleDialogClose();
    else if(nativeScannerOpen)
      setNativeScannerOpen(false);

  }, [open, nativeScannerOpen, handleDialogClose, onResult]);

  const handleScanError = useCallback(err=>{
    console.dir(err);
    setMessage("Allow camera access for live scan.");

  }, []);

  const handleQrImageSelection = useCallback(()=>{
    qrReaderRef.current?.openImageDialog();

  }, []);

  const handleLegacyModeToggle = useCallback(()=>{
    setMessage("");
    setLegacyMode(legacyMode=>!legacyMode);

  }, []);

  const openScanner = useCallback(()=>{
    if(userAgent === 'replenish'){
      (window as any).ReactNativeWebView.postMessage("Hello!");
      setNativeScannerOpen(true);
    }
    else
      setOpen(true);

  }, [userAgent]);

  useImperativeHandle(ref, ()=>({ openScanner }), [openScanner]);

  useEffect(()=>{
    // Execute this effect only on mobile application.
    if(userAgent !== 'replenish' || !nativeScannerOpen)
      return;

    function handleFile(e: any) {
      handleScanResult(e.detail);
    }

    function handleCancel() {
      setNativeScannerOpen(false);
    }

    window.document.addEventListener("filefromnative", handleFile);
    window.document.addEventListener("cancel", handleCancel);

    return () => {
      window.document.removeEventListener("filefromnative", handleFile);
      window.document.removeEventListener("cancel", handleCancel);
    };

  }, [handleScanResult, userAgent, nativeScannerOpen]);

  return (
    <>
      {!hidden && (
        <Button
          startIcon={<ScannerIcon />}
          onClick={openScanner}
          disabled={disabled || open}
          {...ButtonProps}
        >
          {buttonText}
        </Button>
      )}

      <Dialog
        open={open}
        onClose={handleDialogClose}
        maxWidth="xs"
        fullWidth
      >
        <DialogTitle>{title}</DialogTitle>
        <DialogContent>
          <DialogContentText>{message || "--"}</DialogContentText>
          <QrReader
            ref={qrReaderRef}
            facingMode={facingMode}
            onScan={handleScanResult}
            onError={handleScanError}
            legacyMode={legacyMode}
          />

          {legacyMode && (
            <Button
              startIcon={<ImageIcon />}
              onClick={handleQrImageSelection}
              size="small"
            >
              Choose image with QR
            </Button>
          )}
        </DialogContent>
        <DialogActions>
          {!legacyMode && (
            <Button onClick={handleLegacyModeToggle} size="medium">
              Scan from image
            </Button>
          )}
          {legacyMode && (
            <Button onClick={handleLegacyModeToggle} size="medium">
              Live scan
            </Button>
          )}
          <Button color="primary" onClick={handleDialogClose} size="medium">
            Cancel
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default forwardRef(QrCodeScanner);
