
import React from 'react';
import useAuthentication from '../hooks/use-authentication';
import { BrowserRouter, Route, Switch, Redirect, RouteProps } from 'react-router-dom';
import MainLayout from './layouts/MainLayout';
import LandingPage from './LandingPage';
import AcceptInvitePage from './AcceptInvitePage';
import LoginPage from './login-page/LoginPage';
import ForgotPasswordPage from './forgot-password-page/ForgotPasswordPage';
import ResetPasswordPage from './reset-password-page/ResetPasswordPage';
import DashboardPage from './dashboard-page/DashboardPage';
import UsersManagementPage from '../pages/UsersManagementPage';
import ReportsPage from './ReportsPage';
import IAmEmptyPage from '../pages/IAmEmptyPage';
import CustomOrderPage from '../pages/CustomOrderPage';
import SettingsPage from '../pages/SettingsPage';
import VendorsPage from '../pages/VendorsPage';
import PrintersPage from '../pages/PrintersPage';
import LocationsPage from '../pages/LocationsPage';
import LocationPage from '../pages/LocationPage';
import ProductsPage from '../pages/ProductsPage';
import ProductPage from '../pages/ProductPage';
import BinFamiliesPage from '../pages/BinFamiliesPage';
import BinFamilyPage from '../pages/BinFamilyPage';
import BinPage from '../pages/BinPage';
import ShoppingListPage from '../pages/ShoppingListPage';

interface AuthAwareRouteProps extends RouteProps {
  requireAuth?: boolean;
  redirectTo: string;
}

const AuthAwareRoute: React.FC<AuthAwareRouteProps> = function({redirectTo, requireAuth, ...props}) {
  const authenticated = useAuthentication();
  const state: any = props.location?.state;

  if((authenticated && requireAuth) || (!authenticated && !requireAuth))
    return <Route {...props}/>;
  else
    return <Redirect to={{pathname: state && state.from ? state.from.pathname : redirectTo, state: {from: props.location}}} />;
};

const MainRouter: React.FC = function() {
  return (
    <BrowserRouter>
      <Switch>
        <Route path={['/login', '/forgot-password', '/reset-password', '/accept-invite']}>
          <MainLayout hideNavbar>
            <Switch>
              <AuthAwareRoute path="/login" redirectTo="/" component={LoginPage} />
              <AuthAwareRoute path="/forgot-password" redirectTo="/" component={ForgotPasswordPage} />
              <AuthAwareRoute path="/reset-password" redirectTo="/" component={ResetPasswordPage} />
              <AuthAwareRoute path="/accept-invite" redirectTo="/" component={AcceptInvitePage} />
            </Switch>
          </MainLayout>
        </Route>
        <Route path={['/users', '/settings', '/dashboard', '/reports', '/i-am-empty', '/custom-order', '/move-bin-family', '/vendors', '/shopping-list', '/printers', '/locations/:locationId', '/locations', '/products/:productId', '/products', '/bin-families', '/bin-families/:binFamilyId', '/bins/:binId']}>
          <MainLayout NavbarProps={{ hideTitle: true }}>
            <Switch>
              <AuthAwareRoute path="/users" requireAuth redirectTo="/login" component={UsersManagementPage} />
              <AuthAwareRoute path="/settings" requireAuth redirectTo="/login" component={SettingsPage} />
              <AuthAwareRoute path="/dashboard" requireAuth redirectTo="/login" component={DashboardPage} />
              <AuthAwareRoute path="/vendors" requireAuth redirectTo="/login" component={VendorsPage} />
              <AuthAwareRoute path="/printers" requireAuth redirectTo="/login" component={PrintersPage} />
              <AuthAwareRoute path="/locations/:locationId" requireAuth redirectTo="/login" component={LocationPage} />
              <AuthAwareRoute path="/locations" requireAuth redirectTo="/login" component={LocationsPage} />
              <AuthAwareRoute path="/products/:productId" requireAuth redirectTo="/login" component={ProductPage} />
              <AuthAwareRoute path="/products" requireAuth redirectTo="/login" component={ProductsPage} />
              <AuthAwareRoute path="/shopping-list" requireAuth redirectTo="/login" component={ShoppingListPage} />
              <AuthAwareRoute path="/bin-families/:binFamilyId" requireAuth redirectTo="/login" component={BinFamilyPage} />
              <AuthAwareRoute path="/bins/:binId" requireAuth redirectTo="/login" component={BinPage} />
              <AuthAwareRoute path="/bin-families" requireAuth redirectTo="/login" component={BinFamiliesPage} />
              <AuthAwareRoute path="/reports" requireAuth redirectTo="/login" component={ReportsPage} />
              <AuthAwareRoute path="/i-am-empty" requireAuth redirectTo="/login" component={IAmEmptyPage} />
              <AuthAwareRoute path="/custom-order" requireAuth redirectTo="/login" component={CustomOrderPage} />
            </Switch>
          </MainLayout>
        </Route>
        <Route path={['/']}>
          <MainLayout NavbarProps={{ hideDrawer: true }}>
            <Switch>
              <Route path="/" exact component={LandingPage} />
              <Redirect to="/" />
            </Switch>
          </MainLayout>
        </Route>
      </Switch>
    </BrowserRouter>
  );
};

export default MainRouter;
