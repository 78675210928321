
import React, { forwardRef, useImperativeHandle } from 'react';
import { PrinterFormProps, PrinterFormRef, PrinterData } from './types';
import validationSchema from './validation-schema';
import { useFormik } from 'formik';
import Grid from '@material-ui/core/Grid';
import TextField from '@material-ui/core/TextField';

const PrinterForm: React.ForwardRefRenderFunction<PrinterFormRef, PrinterFormProps> = function(props, ref) {
  const {
    initialValues = {} as PrinterData,
    disabled = false,
    onSubmit = ()=>{}
  } = props;

  const formik = useFormik<PrinterData>({
    initialValues: {
      name: initialValues.name || '',
      url: initialValues.url || ''
    },
    validationSchema,
    onSubmit
  });

  useImperativeHandle(ref, ()=>formik, [formik]);

  return (
    <form onSubmit={formik.handleSubmit}>
      <Grid container spacing={2}>
        <Grid item xs={12} md={6}>
          <TextField
            label="NAME"
            type="text"
            {...formik.getFieldProps('name')}
            error={formik.touched['name'] && !!formik.errors['name']}
            helperText={formik.errors['name']}
            disabled={disabled}
            variant="standard"
            size="small"
          />
        </Grid>

        <Grid item xs={12} md={6}>
          <TextField
            label="URL"
            type="text"
            {...formik.getFieldProps('url')}
            error={formik.touched['url'] && !!formik.errors['url']}
            helperText={formik.errors['url']}
            disabled={disabled}
            variant="standard"
            size="small"
          />
        </Grid>
      </Grid>
    </form>
  );
};

export default forwardRef(PrinterForm);
