
import React from 'react';
import { MuiThemeProvider } from '@material-ui/core/styles';
import ConfirmDialogProvider from './providers/ConfirmDialogProvider';
import NotifierProvider from './providers/NotifierProvider';
import CssBaseline from '@material-ui/core/CssBaseline';
import MainRouter from './containers/MainRouter';
import mainTheme from './themes/main-theme';

function App() {
  return (
    <MuiThemeProvider theme={mainTheme}>
      <NotifierProvider>
        <ConfirmDialogProvider>
          <CssBaseline />
          <MainRouter />
        </ConfirmDialogProvider>
      </NotifierProvider>
    </MuiThemeProvider>
  );
}

export default App;
