
import React, { useCallback, useRef } from 'react';
import NotifierContext from './NotifierContext';
import Notifier, { NotifierRef, NotifyMethod } from '../../components/Notifier';

const NotifierProvider: React.FC = function(props) {
  const notifier = useRef<NotifierRef>(null);
  const notify: NotifyMethod = useCallback((message, buttonText)=>{
    if(notifier.current)
      notifier.current.notify(message, buttonText);
    else
      alert(message);

  }, []);

  return (
    <NotifierContext.Provider value={notify}>
      {props.children}
      <Notifier ref={notifier} />
    </NotifierContext.Provider>
  );
};

export default NotifierProvider;
