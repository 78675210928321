
import { useState, useEffect, useCallback } from 'react';
import { binFamiliesService } from '../../services/bin-families';
import BinFamily from '../../models/BinFamily';
import { useNotifier } from '../../providers/NotifierProvider';

export default function useBinFamily(binFamilyId: number): [BinFamily|null, boolean, string] {
  const [binFamily, setBinFamily] = useState<BinFamily | null>(null);
  const [loading, setLoading] = useState(false);
  const [message, setMessage] = useState('');
  const notify = useNotifier();

  const fetchBinFamily = useCallback(async ()=>{
    try{
      setLoading(true);
      const binFamily = await binFamiliesService.fetchById(binFamilyId);
      setBinFamily(binFamily);
      setMessage('');
    }
    catch(err){
      setMessage('Failed to fetch bin family.');
      notify(err);
    }
    finally{
      setLoading(false);
    }
  }, [binFamilyId, notify]);

  useEffect(()=>{
    fetchBinFamily();
  }, [fetchBinFamily]);

  return [binFamily, loading, message];
}
