
import { useState, useEffect, useCallback } from 'react';
import { useNotifier } from '../../providers/NotifierProvider';
import { usersService } from '../../services/api';
import User from '../../models/User';

export default function useUsers(): [User[], boolean, Error|string] {
  const [users, setUsers] = useState<User[]>([]);
  const [message, setMessage] = useState<Error | string>('');
  const [loading, setLoading] = useState(true);
  const notify = useNotifier();

  const fetchUsers = useCallback(async ()=>{
    try{
      setLoading(true);
      const users = await usersService.fetchAll();
      setMessage(users.length === 0 ? 'Nothing found.' : '');
      setUsers(users);
    }
    catch(err){
      notify(err);
      setMessage('Failed to load users.');
    }
    finally{
      setLoading(false);
    }
  }, [notify]);

  useEffect(()=>{
    fetchUsers();

  }, [ fetchUsers ]);

  useEffect(()=>{
    function addUser(user: User){
      setUsers(users=>([...users, user]));
      setMessage('');
    }

    function removeUser(userId: number) {
      setUsers(users=>users.filter(v=>v.id !== userId));
    }

    function updateUser(updatedUser: User, userId: number) {
      setUsers(users=>{
        const index = users.findIndex(u=>u.id === userId);
        const updatedList = [...users];
        updatedList[index] = updatedUser;
        return updatedList;
      });
    }

    usersService.on('user-created', addUser);
    usersService.on('user-deleted', removeUser);
    usersService.on('user-updated', updateUser);

    return ()=>{
      usersService.off('user-created', addUser);
      usersService.off('user-deleted', removeUser);
      usersService.off('user-updated', updateUser);
    }

  }, []);

  return [users, loading, message];
}
