
import React, { useImperativeHandle, forwardRef } from 'react';
import Grid from '@material-ui/core/Grid';
import TextField from '@material-ui/core/TextField';
import InputAdornment from '@material-ui/core/InputAdornment';
import EmailIcon from '@material-ui/icons/EmailOutlined';
import LockIcon from '@material-ui/icons/LockOutlined';
import { AuthenticationData, AuthenticationFormProps, AuthenticationFormRef } from './types';
import { useFormik } from 'formik';
import validationSchema from './validation-schema';

const initialValues: AuthenticationData = { email: '', password: '' };

const AuthenticationForm: React.RefForwardingComponent<AuthenticationFormRef, AuthenticationFormProps> = function(props, ref) {
  const formik = useFormik({
    initialValues,
    validationSchema,
    onSubmit: values=>{
      if(props.onSubmit)
        props.onSubmit(values);
    }
  });

  useImperativeHandle(ref, ()=>formik, [formik]);

  return (
    <form onSubmit={formik.handleSubmit}>
      <Grid container direction="column" spacing={2}>
        <Grid item>
          <TextField
            label="Email address"
            type="email"
            {...formik.getFieldProps('email')}
            disabled={props.disabled}
            error={!!(formik.touched['email'] && formik.errors['email'])}
            helperText={formik.touched['email'] && formik.errors['email']}
            variant="outlined"
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <EmailIcon />
                </InputAdornment>
              )
            }}
          />
        </Grid>
        <Grid item>
          <TextField
            label="Password"
            type="password"
            {...formik.getFieldProps('password')}
            disabled={props.disabled}
            error={!!(formik.touched['password'] && formik.errors['password'])}
            helperText={formik.touched['password'] && formik.errors['password']}
            variant="outlined"
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <LockIcon />
                </InputAdornment>
              )
            }}
          />
        </Grid>
      </Grid>
    </form>
  );
};

export * from './types';
export default forwardRef(AuthenticationForm);
