
import React from 'react';
import moment from 'moment';
import Box from '@material-ui/core/Box';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import TableContainer from '@material-ui/core/TableContainer';
import Table from '@material-ui/core/Table';
import TableHead from '@material-ui/core/TableHead';
import TableBody from '@material-ui/core/TableBody';
import TableFooter from '@material-ui/core/TableFooter';
import TablePagination from '@material-ui/core/TablePagination';
import TableRow from '@material-ui/core/TableRow';
import TableCell from '@material-ui/core/TableCell';
import TextField from '@material-ui/core/TextField';
import Typography from '@material-ui/core/Typography';
import IconButton from '@material-ui/core/IconButton';
import RefreshIcon from '@material-ui/icons/Refresh';
// import TablePaginationActions from './TablePaginationActions';

// TODO: Integrate API
const logs: any = [
  {person: 'Crusty Clown', part_number: 'AA1', description: 'Orange juice mix', quantity: 3, location: 'RMA receive area', timestamp: 1598995615},
  {person: 'Jerry Scott', part_number: 'AA1', description: 'Orange juice mix', quantity: 3, location: 'Quality station 1 triage', timestamp: 1598995700},
  {person: 'Crusty Clown', part_number: 'AA1', description: 'Orange juice mix', quantity: 3, location: 'outside vendor in another state or country', timestamp: 1598995772},
  {person: 'Crusty Clown', part_number: 'AA1', description: 'Orange juice mix', quantity: 3, location: 'RMA receive area', timestamp: 1598995792},
  {person: 'Crusty Clown', part_number: 'AA1', description: 'Orange juice mix', quantity: 3, location: 'Quality station 1 triage', timestamp: 1598995835},
  {person: 'Crusty Clown', part_number: 'AA1', description: 'Orange juice mix', quantity: 3, location: 'discard', timestamp: 1598995846},
  {person: 'Crusty Clown', part_number: 'V001275', description: 'Mini Rad-D', quantity: 1, location: 'RMA arrival', timestamp: 1599247693},
  {person: 'Crusty Clown', part_number: 'V001275', description: 'Mini Rad-D', quantity: 1, location: 'RMA arrival', timestamp: 1599254700},
];

const TransactionLogsPanel: React.FC = function() {
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);

  const handleChangePage = (event: any, newPage: number) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event: any) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  return (
    <Paper>
      <Box p={2}>
        <Grid container spacing={2} alignItems="flex-end">
          <Grid item xs={12} md>
            <Typography variant="h6">Movement</Typography>
            <Typography variant="subtitle2" color="textSecondary">Lorem ipsum dolor sit, amet consectetur</Typography>
          </Grid>
          <Grid item xs={12} md="auto">
            <Grid container alignItems="center" spacing={2}>
              <Grid item>
                <TextField
                  type="date"
                  size="small"
                />
              </Grid>
              <Grid item>
                <Typography variant="subtitle2" color="textSecondary">(to)</Typography>
              </Grid>
              <Grid item>
                <TextField
                  type="date"
                  size="small"
                />
              </Grid>
              <Grid item>
                <IconButton
                  color="secondary"
                  edge="end"
                >
                  <RefreshIcon />
                </IconButton>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Box>
      <Box pt={2}>
        <TableContainer>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>#</TableCell>
                <TableCell>PERSON</TableCell>
                <TableCell>PART #</TableCell>
                <TableCell>DESCRIPTION</TableCell>
                <TableCell>QUANTITY</TableCell>
                <TableCell>LOCATION</TableCell>
                <TableCell>DATE/TIME</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {(rowsPerPage > 0
                ? logs.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                : logs
              ).map((log: any, i: number)=>(
                <TableRow key={'' + i}>
                  <TableCell>{i + 1 + (page * rowsPerPage)}</TableCell>
                  <TableCell>{log.person}</TableCell>
                  <TableCell>{log.part_number}</TableCell>
                  <TableCell>{log.description}</TableCell>
                  <TableCell>{log.quantity}</TableCell>
                  <TableCell>{log.location}</TableCell>
                  <TableCell>{moment.unix(log.timestamp).format('MMM DD, YYYY - hh:mm a')}</TableCell>
                </TableRow>
              ))}
            </TableBody>
            <TableFooter>
              <TableRow>
                <TablePagination
                  align="right"
                  rowsPerPageOptions={[5, 10, 25, { label: 'All', value: -1 }]}
                  colSpan={7}
                  count={logs.length}
                  rowsPerPage={rowsPerPage}
                  page={page}
                  SelectProps={{
                    inputProps: { 'aria-label': 'rows per page' },
                    native: true,
                  }}
                  onChangePage={handleChangePage}
                  onChangeRowsPerPage={handleChangeRowsPerPage}
                  // ActionsComponent={TablePaginationActions}
                />
              </TableRow>
            </TableFooter>
          </Table>
        </TableContainer>
      </Box>
    </Paper>
  )
};

export default TransactionLogsPanel;
