
import React, { useRef } from 'react';
import randomize from 'randomatic';
import * as orderTrackService from '../../services/order-track';
import { binsService } from '../../services/api';
import { binFamiliesService } from '../../services/bin-families';
import useBinFamilies from '../../hooks/use-bin-families';
import { useNotifier } from '../../providers/NotifierProvider';
import BinFamilyDialog, { BinFamilyDialogRef, BinFamilyData } from '../../dialogs/BinFamilyDialog';
import CardsView from './CardsView';
import Container from '@material-ui/core/Container';
import Box from '@material-ui/core/Box';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';
import Fab from '@material-ui/core/Fab';
import LinearProgress from '@material-ui/core/LinearProgress';
import InfoMessage from '../../components/InfoMessage';
import AddIcon from '@material-ui/icons/Add';

const BinFamiliesPage: React.FC = function() {
  const [binFamilies, loading, message] = useBinFamilies();
  const binFamilyDialog = useRef<BinFamilyDialogRef>(null);
  const notify = useNotifier();

  async function handleBinFamilyCreate(data: BinFamilyData) {
    const binFamily = await binFamiliesService.create(data);
    
    notify('Creating bins...');
    
    const randomString = randomize('A0', 4);
    const bins = await Promise.all([
      binsService.create({ name: `BIN A - ${binFamily.id} - ${randomString}`, bin_family_id: binFamily.id }),
      binsService.create({ name: `BIN B - ${binFamily.id} - ${randomString}`, bin_family_id: binFamily.id })
    ]);

    notify('Printing labels...');
    const labelsPdf = await orderTrackService.generateBinLabels(binFamily.product, bins[0], bins[1]);

    window.open(labelsPdf, '_blank');
    notify('New bin family created.');
  }

  return (
    <Container maxWidth={false}>
      <Box py={2}>
        <Paper>
          <Box p={2}>
            <Grid container spacing={2} justify="space-between" alignItems="center">
              <Grid item>
                <Typography variant="h6">Bin families</Typography>
                <Typography variant="body2" color="textSecondary">Create/Update bin families</Typography>
              </Grid>
              <Grid item>
                <Fab
                  size="medium"
                  color="primary"
                  onClick={()=>binFamilyDialog.current?.openDialog(true)}
                >
                  <AddIcon />
                </Fab>
              </Grid>
            </Grid>
          </Box>

          {!loading && !!message && (
            <Box p={2}>
              <InfoMessage>{message}</InfoMessage>
            </Box>
          )}
        </Paper>

        {loading && (
          <LinearProgress />
        )}

        <CardsView
          binFamilies={binFamilies}
          disabled={loading}
        />
      </Box>

      <BinFamilyDialog
        ref={binFamilyDialog}
        primaryActionText="Create"
        secondaryActionText="Cancel"
        primaryAction={handleBinFamilyCreate}
      />
    </Container>
  );
}

export default BinFamiliesPage;
