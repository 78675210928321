
import api from '../api-client';
import EventEmitter from 'events';
import { authenticationStore } from '../store';
import User from '../../models/User';
import { ProfileInfo } from './types';

// TODO: Reload user info from server on application startup, in case info was updated from another device.

export default class AuthenticationService extends EventEmitter {
  isAuthenticated() {
    const token = authenticationStore.get('access_token');
    const user = authenticationStore.get('user');

    return !!token && !!user;
  }

  async fetchCurrentUser() {
    const response = await api.get(`/api/${authenticationStore.get('org_slug')}/users/me`);
    return new User(response.data);
  }

  async login(email: string, password: string) {
    const formData = new FormData();
    formData.append('email', email);
    formData.append('password', password);
  
    const url = `/auth/${authenticationStore.get('org_slug')}/system/login`;
    const response = await api.post(url, formData);
    const data = response.data;
    
    authenticationStore.saveToken(data.access_token);
    authenticationStore.saveRefreshToken(data.refresh_token);
    authenticationStore.saveOrgSlug(data.org_slug);

    const user = await this.fetchCurrentUser();
    authenticationStore.saveUser(user);
  
    this.emit('login', user);
    return 'You are logged in.';
  }

  logout() {
    authenticationStore.clearAll();
    this.emit('logout');
  }

  async updatePassword(password: string): Promise<string> {
    const url = `/api/${authenticationStore.get('org_slug')}/users/update_password`;
    const response = await api.post(url, { password });
    return response.data;
  }

  async updateCurrentUser(updateData: ProfileInfo) {
    const url = `/api/${authenticationStore.get('org_slug')}/users/me`;
    const response = await api.put(url, updateData);
  
    let updatedUser = new User(response.data);
    authenticationStore.saveUser(updatedUser);

    return updatedUser
  }

  async requestPasswordReset(email: string): Promise<string> {
    if(typeof email !== 'string' || !email.trim().length)
      throw new Error('Enter a valid email address.');

    const formData = new FormData();
    formData.append('email', email);
  
    const url = `/auth/${authenticationStore.get('org_slug')}/system/forgot`;
    const response = await api.post(url, formData);
    return response.data; // Success message.
  }

  async resetPassword(password: string, token: string): Promise<string> {
    const url = `/auth/${authenticationStore.get('org_slug')}/system/reset/${token}`;
    const response = await api.post(url, { password });
    const data = response.data;

    authenticationStore.set('access_token', data.access_token);
    return this.updatePassword(password);
  }

  async sendInvite(email: string): Promise<string> {
    const url = `/api/${authenticationStore.get('org_slug')}/users/invite`;
    const response = await api.post(url, { email });
    return response.data; // Success message.
  }

  async acceptInvite(token: string): Promise<string> {
    const url = `/auth/${authenticationStore.get('org_slug')}/system/invite-accept/${token}`;
    const response = await api.get(url/*, {
      params: { token }
    }*/)
  
    return response.data;
  }
}
