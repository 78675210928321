
import { useState, useEffect, useCallback } from 'react';
import { useNotifier } from '../../providers/NotifierProvider';
import { locationsService } from '../../services/api';
import Location from '../../models/Location';

export default function useLocations(): [Location[], boolean, Error|string] {
  const [locations, setLocations] = useState<Location[]>([]);
  const [message, setMessage] = useState<Error | string>('');
  const [loading, setLoading] = useState(true);
  const notify = useNotifier();

  const fetchLocations = useCallback(async ()=>{
    try{
      setLoading(true);
      const locations = await locationsService.fetchAll();
      setMessage(locations.length === 0 ? 'Nothing found.' : '');
      setLocations(locations);
    }
    catch(err){
      notify(err);
      setMessage('Failed to load locations.');
    }
    finally{
      setLoading(false);
    }
  }, [notify]);

  useEffect(()=>{
    fetchLocations();

  }, [ fetchLocations ]);

  useEffect(()=>{
    function addLocation(location: Location){
      setLocations(locations=>([...locations, location]));
      setMessage('');
    }

    function removeLocation(locationId: number) {
      setLocations(locations=>locations.filter(v=>v.id !== locationId));
    }

    function updateLocation(updatedLocation: Location, locationId: number) {
      setLocations(locations=>{
        const index = locations.findIndex(l=>l.id === locationId);
        const updatedList = [...locations];
        updatedList[index] = updatedLocation;
        return updatedList;
      });
    }

    locationsService.on('location-created', addLocation);
    locationsService.on('location-deleted', removeLocation);
    locationsService.on('location-updated', updateLocation);

    return ()=>{
      locationsService.off('location-created', addLocation);
      locationsService.off('location-deleted', removeLocation);
      locationsService.off('location-updated', updateLocation);
    }

  }, []);

  return [locations, loading, message];
}
