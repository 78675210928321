
import React from 'react';
import useUsers from '../../hooks/use-users';
import Container from '@material-ui/core/Container';
import Box from '@material-ui/core/Box';
import Paper from '@material-ui/core/Paper';
import Grid from '@material-ui/core/Grid';
import LinearProgress from '@material-ui/core/LinearProgress';
import Typography from '@material-ui/core/Typography';
import TableContainer from '@material-ui/core/TableContainer';
import Table from '@material-ui/core/Table';
import TableHead from '@material-ui/core/TableHead';
import TableBody from '@material-ui/core/TableBody';
import TableRow from '@material-ui/core/TableRow';
import TableCell from '@material-ui/core/TableCell';
import InfoMessage from '../../components/InfoMessage';
import UserRow from './UserRow';
import InviteUserPanel from '../../containers/invite-user-panel/InviteUserPanel';

const UsersManagementPage: React.FC = function() {
  const [users, loading, message] = useUsers();

  return (
    <Container maxWidth={false}>
      <Box py={2}>
        <Grid container spacing={2}>
          <Grid item xs={12} md={8} lg={9}>
            <Paper>
              <Box p={2}>
                <Typography variant="h6">Users</Typography>
                <Typography variant="subtitle2" color="textSecondary">Manage users &amp; accounts</Typography>
              </Box>

              <TableContainer>
                <Table size="small">
                  <TableHead>
                    <TableRow>
                      <TableCell>PERSON ID</TableCell>
                      <TableCell>NAME</TableCell>
                      <TableCell>EMAIL</TableCell>
                      <TableCell />
                    </TableRow>
                  </TableHead>

                  <TableBody>
                    {users.map(user=>(
                      <UserRow key={''+user.id} user={user} />
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>

              {!loading && !!message && (
                <Box p={2}>
                  <InfoMessage>{ message }</InfoMessage>
                </Box>
              )}

              {loading && <LinearProgress />}
            </Paper>
          </Grid>

          <Grid item xs={12} md={4} lg={3}>
            <InviteUserPanel />
          </Grid>
        </Grid>
      </Box>
    </Container>
  );
};

export default UsersManagementPage;
