
import { useState, useEffect, useCallback } from 'react';
import { useNotifier } from '../../providers/NotifierProvider';
import { productsService } from '../../services/api';
import Product from '../../models/Product';

export default function useProducts(): [Product[], boolean, Error|string] {
  const [products, setProducts] = useState<Product[]>([]);
  const [message, setMessage] = useState<Error | string>('');
  const [loading, setLoading] = useState(true);
  const notify = useNotifier();

  const fetchProducts = useCallback(async ()=>{
    try{
      setLoading(true);
      const products = await productsService.fetchAll();
      setMessage(products.length === 0 ? 'Nothing found.' : '');
      setProducts(products);
    }
    catch(err){
      notify(err);
      setMessage('Failed to load products.');
    }
    finally{
      setLoading(false);
    }
  }, [notify]);

  useEffect(()=>{
    fetchProducts();

  }, [ fetchProducts ]);

  useEffect(()=>{
    function addProduct(product: Product){
      setProducts(products=>([...products, product]));
      setMessage('');
    }

    function removeProduct(productId: number) {
      setProducts(products=>products.filter(v=>v.id !== productId));
    }

    function updateProduct(updatedProduct: Product, productId: number) {
      setProducts(products=>{
        const index = products.findIndex(p=>p.id === productId);
        const updatedList = [...products];
        updatedList[index] = updatedProduct;
        return updatedList;
      });
    }

    productsService.on('product-created', addProduct);
    productsService.on('product-deleted', removeProduct);
    productsService.on('product-updated', updateProduct);

    return ()=>{
      productsService.off('product-created', addProduct);
      productsService.off('product-deleted', removeProduct);
      productsService.off('product-updated', updateProduct);
    }

  }, []);

  return [products, loading, message];
}
