
import axios from 'axios';
import ApiService from '../api';
import BinFamily from '../../models/BinFamily';
import Bin from '../../models/Bin';
import apiClient from '../api-client';
import { momentTrackService } from '../moment-track';
import { binsService } from '../api';
import binLabelsTemplate from '../../label-templates/bin-labels';
import binLabelsSheetTemplate from '../../label-templates/bin-labels-sheet';

export default class BinFamiliesService extends ApiService<BinFamily> {
  async moveFamily(binFamilyId: number, newLocationId: number) {
    const url = `${this.resourcePath()}/${binFamilyId}/relocate`;
    const response = await apiClient.post(url, { location_id: newLocationId });

    const updatedBinFamily = this.deserialize(response.data);
    this.emit(`${this.resourceName}-updated`, updatedBinFamily, binFamilyId);

    return updatedBinFamily;
  }

  printPdf(pdfUrl: string) {
    return axios.post('https://k2vpki1p5i.execute-api.us-west-1.amazonaws.com/prod/printNode', {
      "printer_url": "3hd.us/b23JFAFladW8KeD5GxFX9jeTK",
      "title": "Small label",
      "contentType": "pdf_uri",
      "content": pdfUrl,
      "expireAfter": 600,
      "passkey": "jaredeggettiscool"
    });
  }

  async generateLabels(binFamilies: BinFamily[], templateType: 'single' | 'sheet' = 'single') {
    const allBins = await binsService.fetchAll();
    const binsMap = allBins.reduce((map, bin, i)=>{
      map[bin.bin_family_id] = map[bin.bin_family_id] || [];
      map[bin.bin_family_id].push(bin);
      return map;

    }, {} as {[key: number]: Bin[]});

    let labelsData: any = [];

    binFamilies.forEach(binFamily=>{
      const bins = binsMap[binFamily.id];
      if(!bins)
        return;

      bins.forEach((bin, i)=>{
        labelsData.push({
          type: `Bottom ${i===0 ? 'A' : 'B'} - Reorder`,
          url: momentTrackService.generateUrl(bin.id, 'document'),
          iconUrl: 'https://replenish-icons.s3-us-west-1.amazonaws.com/current_icons/document.svg',
          part_number: binFamily.product.part_number,
          product_name: binFamily.product.name
        });

        labelsData.push({
          type: `Front ${i===0 ? 'A' : 'B'} - Reorder`,
          url: momentTrackService.generateUrl(bin.id, 'licence_plate'),
          iconUrl: 'https://replenish-icons.s3-us-west-1.amazonaws.com/current_icons/licence_plate_icon.svg',
          part_number: binFamily.product.part_number,
          product_name: binFamily.product.name
        },)
      });
    });

    let requestData;

    if(templateType === 'single'){
      requestData = {
        html: binLabelsTemplate(labelsData),
        pdf_mode: 'portrait',
        pdf_name: 'bin-labels',
        page_width: '66.675mm',
        page_height: '25.4mm',
        margin_top: '0mm',
        margin_bottom: '0mm',
        margin_left: '0mm',
        margin_right: '0mm',
        disable_smart_shrinking: null
      };
    }
    else{
      const singleSheetLabelsCount = 30;
      const sheetsCount = Math.ceil(labelsData.length / singleSheetLabelsCount);
      const sheets: any = [];

      for(let i=0; i<sheetsCount; i++){
        const startIndex = i * singleSheetLabelsCount;
        const endIndex = startIndex + singleSheetLabelsCount;
        sheets.push(labelsData.slice(startIndex, endIndex));
      }

      requestData = {
        html: binLabelsSheetTemplate(sheets),
        pdf_mode: 'portrait',
        pdf_name: 'bin-labels',
        page_size: 'Letter',
        margin_top: '0mm',
        margin_bottom: '0mm',
        margin_left: '0mm',
        margin_right: '0mm',
        disable_smart_shrinking: null
      };
    }
  
    const response = await axios.post('https://api.3hd.us/app/html2pdf.generate_pdf', requestData);
    await this.printPdf(response.data.data?.s3_path);
    return response.data.data?.s3_path;
  }
}
