
import React, { useRef } from 'react';
import { printersService } from '../../services/api';
import usePrinters from '../../hooks/use-printers';
import { useNotifier } from '../../providers/NotifierProvider';
import PrinterDialog, { PrinterDialogRef, PrinterData } from '../../dialogs/PrinterDialog';
import Container from '@material-ui/core/Container';
import Box from '@material-ui/core/Box';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';
import Fab from '@material-ui/core/Fab';
import LinearProgress from '@material-ui/core/LinearProgress';
import InfoMessage from '../../components/InfoMessage';
import PrinterCard from './PrinterCard';
import AddIcon from '@material-ui/icons/Add';

const PrintersPage: React.FC = function() {
  const [printers, loading, message] = usePrinters();
  const printerDialog = useRef<PrinterDialogRef>(null);
  const notify = useNotifier();

  async function handlePrinterCreate(data: PrinterData) {
    await printersService.create(data);
    notify('Printer created.');
  }

  return (
    <Container maxWidth={false}>
      <Box py={2}>
        <Paper>
          <Box p={2}>
            <Grid container spacing={2} justify="space-between" alignItems="center">
              <Grid item>
                <Typography variant="h6">Printers</Typography>
                <Typography variant="body2" color="textSecondary">Add/Update printers</Typography>
              </Grid>
              <Grid item>
                <Fab
                  size="medium"
                  color="primary"
                  onClick={()=>printerDialog.current?.openDialog(true)}
                >
                  <AddIcon />
                </Fab>
              </Grid>
            </Grid>
          </Box>

          {!loading && !!message && (
            <Box p={2}>
              <InfoMessage>{message}</InfoMessage>
            </Box>
          )}
        </Paper>

        {loading && (
          <LinearProgress />
        )}

        <Box py={2}>
          <Grid container spacing={1}>
            {printers.map(printer=>(
              <Grid key={''+printer.id} item xs={12} sm={4} md={3} xl={2}>
                <PrinterCard printer={printer} />
              </Grid>
            ))}
          </Grid>
        </Box>
      </Box>

      <PrinterDialog
        ref={printerDialog}
        primaryActionText="Create"
        secondaryActionText="Cancel"
        primaryAction={handlePrinterCreate}
      />
    </Container>
  );
}

export default PrintersPage;
