
import { useState, useEffect, useCallback } from 'react';
import { locationsService } from '../../services/api';
import Location from '../../models/Location';
import { useNotifier } from '../../providers/NotifierProvider';

export default function useLocation(locationId: number): [Location|null, boolean, string] {
  const [location, setLocation] = useState<Location | null>(null);
  const [loading, setLoading] = useState(false);
  const [message, setMessage] = useState('');
  const notify = useNotifier();

  const fetchLocation = useCallback(async ()=>{
    try{
      setLoading(true);
      const location = await locationsService.fetchById(locationId);
      setLocation(location);
      setMessage('');
    }
    catch(err){
      setMessage('Failed to fetch location.');
      notify(err);
    }
    finally{
      setLoading(false);
    }
  }, [locationId, notify]);

  useEffect(()=>{
    fetchLocation();
  }, [fetchLocation]);

  return [location, loading, message];
}
