
import React, { useState, useRef } from 'react';
import { usersService } from '../../services/api';
import { UserEditDialogProps } from './types';
import { useNotifier } from '../../providers/NotifierProvider';
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import DialogActions from '@material-ui/core/DialogActions';
import Button from '@material-ui/core/Button';
import UserForm from '../../components/user-form/UserForm';

const UserEditDialog: React.FC<UserEditDialogProps> = function(props) {
  const { user, onClose, ...dialogProps } = props;
  const userForm = useRef<any>();
  const notify = useNotifier();

  const [loading, setLoading] = useState(false);

  async function handleSave(data: any) {
    try{
      setLoading(true);
      await usersService.updateById(user.id, data);
      notify('User updated.');
      onClose && onClose();
    }
    catch(err){
      notify(err);
    }
    finally{
      setLoading(false);
    }
  }

  return (
    <Dialog
      disableBackdropClick={loading}
      disableEscapeKeyDown={loading}
      maxWidth="xs"
      fullWidth
      {...dialogProps}
    >
      <DialogTitle>Edit user profile</DialogTitle>
      <DialogContent>
        <UserForm
          ref={userForm}
          initialValues={user}
          onSubmit={handleSave}
          disabled={loading}
        />
      </DialogContent>
      <DialogActions>
        <Button disabled={loading} onClick={()=>onClose && onClose()}>Cancel</Button>
        <Button disabled={loading} onClick={()=>userForm.current?.submitForm()}>Save</Button>
      </DialogActions>
    </Dialog>
  );
};

export default UserEditDialog;
