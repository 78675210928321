
import { useState, useEffect, useCallback } from 'react';
import { useNotifier } from '../../providers/NotifierProvider';
import { printersService } from '../../services/api';
import Printer from '../../models/Printer';

export default function usePrinters(): [Printer[], boolean, Error|string] {
  const [printers, setPrinters] = useState<Printer[]>([]);
  const [message, setMessage] = useState<Error | string>('');
  const [loading, setLoading] = useState(true);
  const notify = useNotifier();

  const fetchPrinters = useCallback(async ()=>{
    try{
      setLoading(true);
      const printers = await printersService.fetchAll();
      setMessage(printers.length === 0 ? 'Nothing found.' : '');
      setPrinters(printers);
    }
    catch(err){
      notify(err);
      setMessage('Failed to load printers.');
    }
    finally{
      setLoading(false);
    }
  }, [notify]);

  useEffect(()=>{
    fetchPrinters();

  }, [ fetchPrinters ]);

  useEffect(()=>{
    function addPrinter(printer: Printer){
      setPrinters(printers=>([...printers, printer]));
      setMessage('');
    }

    function removePrinter(printerId: number) {
      setPrinters(printers=>printers.filter(v=>v.id !== printerId));
    }

    function updatePrinter(updatedPrinter: Printer, printerId: number) {
      setPrinters(printers=>{
        const index = printers.findIndex(p=>p.id === printerId);
        const updatedList = [...printers];
        updatedList[index] = updatedPrinter;
        return updatedList;
      });
    }

    printersService.on('printer-created', addPrinter);
    printersService.on('printer-deleted', removePrinter);
    printersService.on('printer-updated', updatePrinter);

    return ()=>{
      printersService.off('printer-created', addPrinter);
      printersService.off('printer-deleted', removePrinter);
      printersService.off('printer-updated', updatePrinter);
    }

  }, []);

  return [printers, loading, message];
}
