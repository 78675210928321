
import React, { useRef } from 'react';
import { locationsService } from '../../services/api';
import { generateLocationLabel } from '../../services/order-track';
import useLocations from '../../hooks/use-locations';
import { useNotifier } from '../../providers/NotifierProvider';
import LocationDialog, { LocationDialogRef, LocationData } from '../../dialogs/LocationDialog';
import Container from '@material-ui/core/Container';
import Box from '@material-ui/core/Box';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';
import Fab from '@material-ui/core/Fab';
import LinearProgress from '@material-ui/core/LinearProgress';
import InfoMessage from '../../components/InfoMessage';
import LocationCard from './LocationCard';
import AddIcon from '@material-ui/icons/Add';

const LocationsPage: React.FC = function() {
  const [locations, loading, message] = useLocations();
  const locationDialog = useRef<LocationDialogRef>(null);
  const notify = useNotifier();

  async function handleLocationCreate(data: LocationData) {
    const location = await locationsService.create(data);
    const labelPdf = await generateLocationLabel(location);
    window.open(labelPdf, '_blank');
    notify('Location created.');
  }

  return (
    <Container maxWidth={false}>
      <Box py={2}>
        <Paper>
          <Box p={2}>
            <Grid container spacing={2} justify="space-between" alignItems="center">
              <Grid item>
                <Typography variant="h6">Locations</Typography>
                <Typography variant="body2" color="textSecondary">Add/Update locations</Typography>
              </Grid>
              <Grid item>
                <Fab
                  size="medium"
                  color="primary"
                  onClick={()=>locationDialog.current?.openDialog(true)}
                >
                  <AddIcon />
                </Fab>
              </Grid>
            </Grid>
          </Box>

          {!loading && !!message && (
            <Box p={2}>
              <InfoMessage>{message}</InfoMessage>
            </Box>
          )}
        </Paper>

        {loading && (
          <LinearProgress />
        )}

        <Box py={2}>
          <Grid container spacing={1}>
            {locations.map(location=>(
              <Grid key={''+location.id} item xs={12} sm={4} md={3} xl={2}>
                <LocationCard location={location} />
              </Grid>
            ))}
          </Grid>
        </Box>
      </Box>

      <LocationDialog
        ref={locationDialog}
        primaryActionText="Create"
        secondaryActionText="Cancel"
        primaryAction={handleLocationCreate}
      />
    </Container>
  );
}

export default LocationsPage;
