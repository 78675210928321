
import React, { useRef, useEffect } from 'react';
import ChartJs from 'chart.js';
import { ChartProps } from './types';

const Chart: React.FC<ChartProps> = function(props) {
  const {
    type = 'line'
  } = props;

  const canvas = useRef<HTMLCanvasElement>(null);

  useEffect(()=>{
    if(canvas.current){
      const ctx = canvas.current.getContext('2d') || '';
      new ChartJs(ctx, {
        type,
        data: {
          labels: ['Red', 'Blue', 'Yellow', 'Green', 'Purple', 'Orange'],
          datasets: [{
              label: '# of Votes',
              data: [12, 19, 3, 5, 2, 3],
              backgroundColor: [
                  'rgba(255, 99, 132, 0.2)',
                  'rgba(54, 162, 235, 0.2)',
                  'rgba(255, 206, 86, 0.2)',
                  'rgba(75, 192, 192, 0.2)',
                  'rgba(153, 102, 255, 0.2)',
                  'rgba(255, 159, 64, 0.2)'
              ],
              borderColor: [
                  'rgba(255, 99, 132, 1)',
                  'rgba(54, 162, 235, 1)',
                  'rgba(255, 206, 86, 1)',
                  'rgba(75, 192, 192, 1)',
                  'rgba(153, 102, 255, 1)',
                  'rgba(255, 159, 64, 1)'
              ],
              borderWidth: 1
          }]
        },
        options: {
          scales: {
            yAxes: [{
              ticks: {
                beginAtZero: true
              }
            }]
          }
        }
      });
    }
  }, [type]);

  return <canvas ref={canvas} height={200} />;
};

export default Chart;
