
import { createStyles, Theme } from '@material-ui/core/styles';

export const listItemStyles = (theme: Theme) => createStyles({
  selected: {
    color: theme.palette.primary.main
  }
});

export default (theme: Theme) => createStyles({
  toolbar: {
    [theme.breakpoints.up('md')]: {
      transition: theme.transitions.create('margin', {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen
      }),
      marginLeft: 0
    }
  },
  toolbarShift: {
    [theme.breakpoints.up('md')]: {
      transition: theme.transitions.create('margin', {
        easing: theme.transitions.easing.easeOut,
        duration: theme.transitions.duration.enteringScreen
      }),
      marginLeft: 256
    }
  },
  drawer: {
    [theme.breakpoints.up('md')]: {
      width: 256,
      transition: theme.transitions.create('margin', {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen
      }),
      marginRight: -256
    }
  },
  drawerShift: {
    [theme.breakpoints.up('md')]: {
      width: 256,
      transition: theme.transitions.create('margin', {
        easing: theme.transitions.easing.easeOut,
        duration: theme.transitions.duration.enteringScreen
      }),
      marginRight: 0
    }
  },
  drawerPaper: {
    width: 320,
    border: 'none',
    [theme.breakpoints.up('md')]: {
      width: 256
    }
  },
  toolbarSpace: {
    flex: 1
  },
  menuIcon: {
    marginRight: theme.spacing(1)
  }
});
