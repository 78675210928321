
import BinFamiliesService from './BinFamiliesService';
import BinFamily from '../../models/BinFamily';
import { authenticationStore } from '../store';
export { default } from './BinFamiliesService';

export const binFamiliesService = new BinFamiliesService(
  ()=>`/api/${authenticationStore.get('org_slug')}/bin_families`,
  'bin-family',
  data=>new BinFamily(data)
);
