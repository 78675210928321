
import React, { useState, useImperativeHandle, useCallback, forwardRef } from 'react';
import { NotifierProps, NotifierRef, MessageConfig } from './types';
import Snackbar from '@material-ui/core/Snackbar';
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogActions from '@material-ui/core/DialogActions';
import Button from '@material-ui/core/Button';

const Notifier: React.ForwardRefRenderFunction<NotifierRef, NotifierProps> = function(props, ref) {
  const { behavior } = props;
  const [open, setOpen] = useState(false);
  const [messageConfig, setMessageConfig] = useState<MessageConfig | null>(null);

  const notify = useCallback((message: string | Error, buttonText: string = 'Ok')=>{
    if(!message)
      return;

    if(message instanceof Error){
      setMessageConfig({
        message: message.message,
        behavior: behavior || 'dialog',
        title: message.name,
        buttonText: buttonText
      });
    }
    else{
      setMessageConfig({
        message: message.toString(),
        behavior: behavior || 'snackbar'
      });
    }

    setOpen(true);

  }, [behavior]);

  useImperativeHandle(ref, ()=>({ notify }), [notify]);

  return (
    <>
      {messageConfig?.behavior === 'snackbar' && (
        <Snackbar
          open={open}
          onClose={()=>setOpen(false)}
          autoHideDuration={8000}
          message={messageConfig.message}
          anchorOrigin={{
            horizontal: "center",
            vertical: 'bottom'
          }}
        />
      )}

      {messageConfig?.behavior === 'dialog' && (
        <Dialog
          open={open}
          onClose={()=>setOpen(false)}
          fullWidth
          maxWidth="xs"
        >
          <DialogTitle>{ messageConfig.title }</DialogTitle>
          <DialogContent>
            <DialogContentText>{ messageConfig.message }</DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={()=>setOpen(false)}>{ messageConfig.buttonText }</Button>
          </DialogActions>
        </Dialog>
      )}
    </>
  );
}

export default forwardRef(Notifier);
