
import Vendor from './Vendor';

export default class Product {
  id: number;
  name: string;
  description: string;
  organization_id: number;
  part_number: string;
  erp_part_number: string;
  preferred_vendor: Vendor;
  preferred_vendor_id: number;
  preferred_vendor_part_number: string;
  created_at: string;
  active: boolean;

  constructor(data: {[key: string]: any}) {
    data = data || {};

    this.id = data.id;
    this.name = data.name || '';
    this.description = data.description || '';
    this.organization_id = data.organization_id;
    this.part_number = data.part_number || '';
    this.erp_part_number = data.erp_part_number || '';
    this.preferred_vendor = new Vendor(data.preferred_vendor);
    this.preferred_vendor_id = data.preferred_vendor_id;
    this.preferred_vendor_part_number = data.preferred_vendor_part_number || '';
    this.created_at = data.created_at;
    this.active = data.active;
  }
}
