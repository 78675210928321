
import * as Yup from 'yup';

export default Yup.object().shape({
  product_id: Yup.number().required('Required.'),
  default_quantity: Yup.number()
    .integer('Should be a whole number.')
    .moreThan(0, 'Should be more than 0.')
    .required('Required.'),
  location_id: Yup.number().required('Required.'),
  preferred_vendor_id: Yup.number().required('Required.')
});
