
import React, { useImperativeHandle, forwardRef } from 'react';
import { ProfileInfo, ProfileInfoFormProps, ProfileInfoFormRef } from './types';
import { useFormik } from 'formik';
import Grid from '@material-ui/core/Grid';
import TextField from '@material-ui/core/TextField';

const defaultValues: ProfileInfo = {
  first_name: '',
  last_name: '',
  phone: ''
};

const ProfileInfoForm: React.ForwardRefRenderFunction<ProfileInfoFormRef, ProfileInfoFormProps> = function(props, ref) {
  const {
    initialValues = {} as ProfileInfo,
    disabled = false,
    onSubmit = ()=>{}
  } = props;

  const formik = useFormik<ProfileInfo>({
    initialValues: {
      first_name: initialValues.first_name || defaultValues.first_name,
      last_name: initialValues.last_name || defaultValues.last_name,
      phone: initialValues.phone || defaultValues.phone
    },
    onSubmit
  });

  useImperativeHandle(ref, ()=>formik, [formik]);

  return (
    <form onSubmit={formik.handleSubmit}>
      <Grid container spacing={2}>
        <Grid item xs={6}>
          <TextField
            label="First name"
            type="text"
            size="small"
            {...formik.getFieldProps('first_name')}
            value={formik.values['first_name'] || ''}
            error={!!(formik.touched['first_name'] && formik.errors['first_name'])}
            helperText={formik.touched['first_name'] && formik.errors['first_name']}
            variant="standard"
            disabled={disabled}
          />
        </Grid>
        <Grid item xs={6}>
          <TextField
            label="Last name"
            type="text"
            size="small"
            {...formik.getFieldProps('last_name')}
            value={formik.values['last_name'] || ''}
            error={!!(formik.touched['last_name'] && formik.errors['last_name'])}
            helperText={formik.touched['last_name'] && formik.errors['last_name']}
            variant="standard"
            disabled={disabled}
          />
        </Grid>
        <Grid item xs={12}>
          <TextField
            label="Phone"
            type="text"
            size="small"
            {...formik.getFieldProps('phone')}
            value={formik.values['phone'] || ''}
            error={!!(formik.touched['phone'] && formik.errors['phone'])}
            helperText={formik.touched['phone'] && formik.errors['phone']}
            variant="standard"
            disabled={disabled}
          />
        </Grid>
      </Grid>
    </form>
  );
};

export default forwardRef(ProfileInfoForm);
