
import React from 'react';
import Box from '@material-ui/core/Box';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';
import Chart from '../../components/Chart';
import TransactionLogsPanel from '../TransactionLogsPanel';

const ReportsPage: React.FC = function() {
  return (
    <Box p={3}>
      <Grid container spacing={3}>
        <Grid item xs={12} sm={6} lg={4}>
          <Paper>
            <Box p={2}>
              <Typography variant="h6">Bar chart</Typography>
              <Typography variant="subtitle2" color="textSecondary">Lorem ipsum dolor sit amet</Typography>
            </Box>
            <Box p={2}>
              <Chart
                type="bar"
              />
            </Box>
          </Paper>
        </Grid>
        <Grid item xs={12} sm={6} lg={4}>
          <Paper>
            <Box p={2}>
              <Typography variant="h6">Line chart</Typography>
              <Typography variant="subtitle2" color="textSecondary">Lorem ipsum dolor sit amet</Typography>
            </Box>
            <Box p={2}>
              <Chart
                type="line"
              />
            </Box>
          </Paper>
        </Grid>
        <Grid item xs={12} sm={6} lg={4}>
          <Paper>
            <Box p={2}>
              <Typography variant="h6">Pie chart</Typography>
              <Typography variant="subtitle2" color="textSecondary">Lorem ipsum dolor sit amet</Typography>
            </Box>
            <Box p={2}>
              <Chart
                type="pie"
              />
            </Box>
          </Paper>
        </Grid>
        <Grid item xs={12}>
          <TransactionLogsPanel />
        </Grid>
      </Grid>
    </Box>
  );
};

export default ReportsPage;
