
import { useState, useEffect } from 'react';
import authService from '../../services/authentication';

export default function useAuthentication() {
  const [authenticated, setAuthenticated] = useState(authService.isAuthenticated());

  useEffect(()=>{
    function updateAuth(user: any){
      setAuthenticated(!!user);
    }

    authService.on('login', updateAuth);
    authService.on('logout', updateAuth);

    return ()=>{
      authService.off('login', updateAuth);
      authService.off('logout', updateAuth);
    }
  }, []);

  return authenticated;
}