
import React, { forwardRef, useImperativeHandle } from 'react';
import { BinFormProps, BinFormRef, BinData } from './types';
import validationSchema from './validation-schema';
import { useFormik } from 'formik';
import TextField from '@material-ui/core/TextField';

const BinForm: React.ForwardRefRenderFunction<BinFormRef, BinFormProps> = function(props, ref) {
  const {
    initialValues = {},
    onSubmit = ()=>{},
    disabled = false

  } = props;

  const formik = useFormik<BinData>({
    initialValues: {
      name: initialValues.name || '',
      bin_family_id: initialValues.bin_family_id
    },
    validationSchema,
    onSubmit
  });

  useImperativeHandle(ref, ()=>formik, [formik]);

  return (
    <form onSubmit={formik.handleSubmit}>
      <TextField
        label="NAME"
        {...formik.getFieldProps('name')}
        error={formik.touched['name'] && !!formik.errors['name']}
        helperText={formik.errors['name'] || formik.errors['bin_family_id']}
        disabled={disabled}
        variant="standard"
        size="small"
      />
    </form>
  );
}

export default forwardRef(BinForm);
