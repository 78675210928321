
import React, { useState, useRef } from 'react';
import moment from 'moment';
import { ShoppingListAccordianProps } from './types';
import { shoppingListsService } from '../../services/shopping-lists';
import { momentTrackService } from '../../services/moment-track';
import { useConfirmDialog } from '../../providers/ConfirmDialogProvider';
import { useNotifier } from '../../providers/NotifierProvider';
import ShoppingListDialog, {ShoppingListDialogRef} from '../../dialogs/ShoppingListDialog';
import Grid from '@material-ui/core/Grid';
import Accordian from '@material-ui/core/Accordion';
import AccordianSummary from '@material-ui/core/AccordionSummary';
import AccordianDetails from '@material-ui/core/AccordionDetails';
import AccordianActions from '@material-ui/core/AccordionActions';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import Divider from '@material-ui/core/Divider';
import CircularProgress from '@material-ui/core/CircularProgress';
import CheckIcon from '@material-ui/icons/Check';

const ShoppingListAccordian: React.FC<ShoppingListAccordianProps> = function(props) {
  const { shoppingList } = props;
  const shoppingListDialog = useRef<ShoppingListDialogRef>(null);
  const confirm = useConfirmDialog();
  const notify = useNotifier();
  const [loading, setLoading] = useState(false);

  async function handleDone() {
    try{
      if(await confirm('Continue?', 'This action will remove this item from the list.')){
        setLoading(true);

        await shoppingListsService.deleteById(shoppingList.id);
        notify('Item removed.');
      }
    }
    catch(err){
      notify(err);
    }
    finally{
      setLoading(false);
    }
  }

  return (
    <>
      <Accordian>
        <AccordianSummary>
          <Grid container spacing={2} alignItems="center">
            <Grid item xs>
              <Typography variant="subtitle1">{ shoppingList.product.name }</Typography>
            </Grid>

            <Divider orientation="vertical" />

            <Grid item xs={2}>
              <Typography>{ shoppingList.quantity }</Typography>
            </Grid>
          </Grid>
        </AccordianSummary>

        <Divider />

        <AccordianDetails>
          <Grid container spacing={2}>
            <Grid item xs={6}>
              <Typography variant="caption" color="textSecondary">CREATED AT</Typography>
              <Typography variant="body1">{moment(shoppingList.created_at).format('MMM DD - hh:mma')}</Typography>
            </Grid>

            <Grid item xs={6}>
              <Typography variant="caption" color="textSecondary">QUANTITY</Typography>
              <Typography variant="body1">{ shoppingList.quantity }</Typography>
            </Grid>

            <Grid item xs={6}>
              <Typography variant="caption" color="textSecondary">VENDOR</Typography>
              <Typography variant="body1">{ shoppingList.vendor.name }</Typography>
            </Grid>

            <Grid item xs={6}>
              <Typography variant="caption" color="textSecondary">LOCATION</Typography>
              <Typography variant="body1">{ shoppingList.location.name }</Typography>
            </Grid>

            <Grid item xs={12}>
              <Typography variant="caption" color="textSecondary">LICENCE PLATE</Typography>
              <Typography variant="body1" noWrap>{ momentTrackService.generateUrl(shoppingList.bin_id, 'licence_plate') }</Typography>
            </Grid>
          </Grid>
        </AccordianDetails>

        <AccordianActions>
          {loading && (
            <CircularProgress size={24} />
          )}

          <Button
            size="small"
            disabled={loading}
            onClick={()=>shoppingListDialog.current?.openDialog()}
          >More...</Button>

          <Button
            color="primary"
            variant="contained"
            size="small"
            disabled={loading}
            startIcon={<CheckIcon />}
            onClick={handleDone}
          >
            Done
          </Button>
        </AccordianActions>
      </Accordian>

      <ShoppingListDialog
        ref={shoppingListDialog}
        shoppingList={shoppingList}
      />
    </>
  );
};

export default ShoppingListAccordian;
