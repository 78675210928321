
import React, { useState } from 'react';
import { binFamiliesService } from '../../services/bin-families';
import { useConfirmDialog } from '../../providers/ConfirmDialogProvider';
import { useNotifier } from '../../providers/NotifierProvider';
import BinFamily from '../../models/BinFamily';
import { CardsViewProps } from './types';
import MenuButton from '../../components/MenuButton';
import BinFamilyCard from './BinFamilyCard';
import Box from '@material-ui/core/Box';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import CircularProgress from '@material-ui/core/CircularProgress';

const CardsView: React.FC<CardsViewProps> = function(props) {
  const { binFamilies, disabled } = props;

  const confirm = useConfirmDialog();
  const notify = useNotifier();
  const [selectMode, setSelectMode] = useState(false);
  const [selection, setSelection] = useState<BinFamily[]>([]);
  const [generatingLabels, setGeneratingLabels] = useState(false);

  function handleCardSelectionChange(selected: boolean, binFamily: BinFamily) {
    setSelection(selection=>{
      if(selected)
        return [...selection, binFamily];
      else
        return selection.filter(family=>family.id !== binFamily.id);
    });
  }

  async function handleGenerateLables(templateType: 'single' | 'sheet') {
    try{
      if(!selection.length)
        throw new Error('Please select one or more bin families to print labels for.');

      if(!(await confirm('Generate labels', `Generating labels for ${selection.length} bin families.`)))
        return;

      setGeneratingLabels(true);
      await binFamiliesService.generateLabels(selection, templateType);
      notify('Labels generated.');
      setSelection([]);
      setSelectMode(false);
    }
    catch(err){
      notify(err);
    }
    finally{
      setGeneratingLabels(false);
    }
  }

  return (
    <>
      <Box py={2}>
        <Grid container spacing={2} alignItems="center" justify="flex-start">
          {!selectMode && (
            <Grid item>
              <Button
                color="primary"
                variant="contained"
                disabled={disabled || generatingLabels}
                onClick={()=>setSelectMode(true)}
              >
                Select
              </Button>
            </Grid>
          )}

          {selectMode && (
            <>
              <Grid item>
                <Button
                  color="primary"
                  variant="contained"
                  disabled={disabled || generatingLabels}
                  onClick={()=>{setSelectMode(false); setSelection([]);}}
                >
                  Done
                </Button>
              </Grid>
              {selection.length < binFamilies.length && (
                <Grid item>
                  <Button
                    variant="outlined"
                    disabled={disabled || generatingLabels}
                    onClick={()=>setSelection(binFamilies)}
                  >
                    Select all
                  </Button>
                </Grid>
              )}
              {selection.length > 0 && (
                <Grid item>
                  <Button
                    variant="outlined"
                    disabled={disabled || generatingLabels}
                    onClick={()=>setSelection([])}
                  >
                    Deselect all
                  </Button>
                </Grid>
              )}
              <Grid item>
                <MenuButton
                  menuItems={[
                    { label: 'Single label template (roll)', onClick: ()=>handleGenerateLables('single') },
                    { label: '30 labels sheet template', onClick: ()=>handleGenerateLables('sheet') },
                  ]}
                  variant="outlined"
                  onClick={()=>handleGenerateLables('single')}
                  disabled={disabled || generatingLabels || !selection.length}
                >
                  Generate labels
                </MenuButton>
              </Grid>
            </>
          )}
          {generatingLabels && (
            <Grid item>
              <CircularProgress size={24} />
            </Grid>
          )}
        </Grid>
      </Box>

      <Grid container spacing={1}>
        {binFamilies.map(binFamily=>(
          <Grid key={''+binFamily.id} item xs={12} sm={4} md={3}>
            <BinFamilyCard
              binFamily={binFamily}
              selected={selection.indexOf(binFamily) > -1}
              selectMode={selectMode}
              onChange={handleCardSelectionChange}
              disabled={disabled || generatingLabels}
            />
          </Grid>
        ))}
      </Grid>
    </>
  );
}

export default CardsView;
