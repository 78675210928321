
import { useState, useEffect, useCallback } from 'react';
import { useNotifier } from '../../providers/NotifierProvider';
import { vendorsService } from '../../services/api';
import Vendor from '../../models/Vendor';

export default function useVendors(): [Vendor[], boolean, Error|string] {
  const [vendors, setVendors] = useState<Vendor[]>([]);
  const [message, setMessage] = useState<Error | string>('');
  const [loading, setLoading] = useState(true);
  const notify = useNotifier();

  const fetchVendors = useCallback(async ()=>{
    try{
      setLoading(true);
      const vendors = await vendorsService.fetchAll();
      setMessage(vendors.length === 0 ? 'Nothing found.' : '');
      setVendors(vendors);
    }
    catch(err){
      notify(err);
      setMessage('Failed to load vendors.');
    }
    finally{
      setLoading(false);
    }
  }, [notify]);

  useEffect(()=>{
    fetchVendors();

  }, [ fetchVendors ]);

  useEffect(()=>{
    function addVendor(vendor: Vendor){
      setVendors(vendors=>([...vendors, vendor]));
      setMessage('');
    }

    function removeVendor(vendorId: number) {
      setVendors(vendors=>vendors.filter(v=>v.id !== vendorId));
    }

    function updateVendor(updatedVendor: Vendor, vendorId: number) {
      setVendors(vendors=>{
        const index = vendors.findIndex(v=>v.id === vendorId);
        const updatedList = [...vendors];
        updatedList[index] = updatedVendor;
        return updatedList;
      });
    }

    vendorsService.on('vendor-created', addVendor);
    vendorsService.on('vendor-deleted', removeVendor);
    vendorsService.on('vendor-updated', updateVendor);

    return ()=>{
      vendorsService.off('vendor-created', addVendor);
      vendorsService.off('vendor-deleted', removeVendor);
      vendorsService.off('vendor-updated', updateVendor);
    }

  }, []);

  return [vendors, loading, message];
}
