
import React, { useState, FormEvent, useEffect, useRef } from 'react';
import authService from '../../services/authentication';
import { makeStyles } from '@material-ui/core/styles';
import { useNotifier } from '../../providers/NotifierProvider';
import { Link as RouterLink, RouteComponentProps } from 'react-router-dom';
import Grid from '@material-ui/core/Grid';
import Box from '@material-ui/core/Box';
import Container from '@material-ui/core/Container';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import Link from '@material-ui/core/Link';
import LinearProgress from '@material-ui/core/LinearProgress';
import CountdownText, { CountdownTextRef } from '../../components/CountdownText';
import styles from './styles';

const useStyles = makeStyles(styles);

const ForgotPasswordPage: React.FC<RouteComponentProps> = function() {
  const classes = useStyles();
  const [email, setEmail] = useState('');
  const [requestSent, setRequestSent] = useState(false);
  const [waiting, setWaiting] = useState(false);
  const [loading, setLoading] = useState(false);
  const notify = useNotifier();
  const timer = useRef<CountdownTextRef>(null);

  async function handleSubmit(ev: FormEvent) {
    try{
      ev.preventDefault();
      setLoading(true);

      const message = await authService.requestPasswordReset(email);
      setRequestSent(true);
      setWaiting(true);
      notify(message);
    }
    catch(err){
      notify(err);
    }
    finally{
      setLoading(false);
    }
  }

  useEffect(()=>{
    if(waiting)
      timer.current?.start();

  }, [waiting]);

  return (
    <div className={classes.root}>
      <Container maxWidth="xs">
        <Paper>
          {loading && <LinearProgress />}
          <Box p={3}>
            <Typography variant="h6" gutterBottom>Forgot password?</Typography>
            {!requestSent && <Typography variant="subtitle2" color="textSecondary">
              Enter your email address and hit continue to get password reset link.
            </Typography>}
            {requestSent && <Typography variant="subtitle2" color="textSecondary">
              A password reset link has been sent to your email address.
            </Typography>}
          </Box>

          <form onSubmit={handleSubmit}>
            <Box px={3} pt={3} pb={6}>
              {!requestSent && (
                <TextField
                  label="Email address"
                  type="email"
                  value={email}
                  onChange={ev=>setEmail(ev.target.value)}
                  disabled={loading}
                />
              )}
              {requestSent && (
                <>
                  <Typography variant="subtitle2" color="textSecondary" gutterBottom>Email address</Typography>
                  <Typography variant="body1" color="primary">{ email }</Typography>
                </>
              )}
            </Box>

            <Box p={3}>
              <Grid container spacing={2} alignItems="center" justify="flex-end">
                <Grid item>
                  <Typography>
                    <Link
                      component={RouterLink}
                      to="/login"
                      color="textPrimary"
                      variant="body1"
                      underline="always"
                    >
                      back to login
                    </Link>
                  </Typography>
                </Grid>
                <Grid item>
                  {!requestSent && (
                    <Button type="submit" variant="contained" color="primary" disabled={loading || waiting}>Continue</Button>
                  )}
                  {requestSent && (
                    <Button type="submit" variant={waiting ? 'outlined' : 'contained'} color="primary" disabled={loading || waiting}>
                      Re-send {waiting && (
                        <CountdownText
                          ref={timer}
                          startValue={30}
                          onEnd={()=>setWaiting(false)}
                          textBefore="("
                          textAfter="s)"
                        />
                      )}
                    </Button>
                  )}
                </Grid>
              </Grid>
            </Box>
          </form>
        </Paper>
      </Container>
    </div>
  );
};

export default ForgotPasswordPage;
