
import React from 'react';
import User from '../../models/User';
import { makeStyles } from '@material-ui/core/styles';
import useCurrentUser from '../../hooks/use-current-user';
import { Link as RouterLink } from 'react-router-dom';
import Box from '@material-ui/core/Box';
import Paper from '@material-ui/core/Paper';
import Grid from '@material-ui/core/Grid';
import Avatar from '@material-ui/core/Avatar';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import Link from '@material-ui/core/Link';
import EditIcon from '@material-ui/icons/Edit';
import styles from './styles';

const useStyles = makeStyles(styles);

const DashboardPage: React.FC = function() {
  const currentUser = useCurrentUser() as User;
  const classes = useStyles();

  return (
    <Box p={3}>
      <Grid container spacing={3}>
        <Grid item xs={12} md>
          <Paper>
            <Box p={2}>
              <Typography variant="h6">Profile</Typography>
            </Box>
            <Box p={2}>
              <Grid container spacing={2} alignItems="flex-end">
                <Grid item>
                  <Avatar className={classes.avatar}/>
                </Grid>
                <Grid item>
                  <Typography variant="h5">{currentUser.first_name} {currentUser.last_name}</Typography>
                  <Typography variant="subtitle1"><i>{currentUser.email}</i></Typography>
                </Grid>
              </Grid>
            </Box>

            <Box p={2}>
              <Typography gutterBottom>
                Lorem ipsum dolor sit amet consectetur adipisicing elit.
                Voluptatibus laboriosam unde quisquam beatae aut dolor cum possimus reprehenderit repellat porro.
                Nulla architecto molestias ex minima veniam debitis doloremque minus enim.
              </Typography>
            </Box>
            
          </Paper>
        </Grid>
        <Grid item xs={12} md={4} lg={3}>
          <Paper>
            <Box p={2}>
              <Link to="/settings/profile" underline="none" component={RouterLink}>
                <Button color="secondary" startIcon={<EditIcon />}>Edit profile</Button>
              </Link>
            </Box>
          </Paper>
        </Grid>
      </Grid>
    </Box>
  );
};

export default DashboardPage;
