
import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Link as RouterLink } from 'react-router-dom';
import Box from '@material-ui/core/Box';
import Container from '@material-ui/core/Container';
import Grid from '@material-ui/core/Grid';
import Link from '@material-ui/core/Link';
import Button from '@material-ui/core/Button';
import styles from './styles';

const useStyles = makeStyles(styles);

const LandingPage: React.FC = function() {
  const classes = useStyles(styles);

  return (
    <Box py={4}>
      <Container>
        <Grid container spacing={2} justify="center">
          <Grid item xs={12} sm={8} md={4} lg={3}>
            <Link underline="none" to="/dashboard" component={RouterLink}>
              <Button className={classes.bigButton}
                variant="outlined"
                fullWidth
              >
                Dashboard
              </Button>
            </Link>
          </Grid>
          <Grid item xs={12} sm={8} md={4} lg={3}>
            <Link underline="none" to="/i-am-empty" component={RouterLink}>
              <Button className={classes.bigButton}
                variant="outlined"
                fullWidth
              >
                Scan empty to auto add
              </Button>
            </Link>
          </Grid>
          <Grid item xs={12} sm={8} md={4} lg={3}>
            <Link underline="none" to="/custom-order" component={RouterLink}>
              <Button className={classes.bigButton}
                variant="outlined"
                fullWidth
              >
                Scan to order specific amount
              </Button>
            </Link>
          </Grid>
        </Grid>
      </Container>
    </Box>
  );
}

export default LandingPage;
