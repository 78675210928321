
import { createStyles, Theme } from '@material-ui/core/styles';
import { InfoMessageProps } from './types';

export default (theme: Theme) => createStyles({
  root: ({ textColor }: InfoMessageProps)=>({
    color: textColor && theme.palette[textColor] ? theme.palette[textColor].main : theme.palette.text.primary
  }),
  icon: ({ textColor })=>({
    verticalAlign: 'text-bottom',
    marginRight: theme.spacing(1)/2,
    color: textColor && theme.palette[textColor] ? theme.palette[textColor].main : theme.palette.text.primary
  })
});
