
import { createStyles, Theme } from '@material-ui/core/styles';

export default (theme: Theme) => createStyles({
  bigButton: {
    paddingTop: theme.spacing(1),
    paddingBottom: theme.spacing(1),
    textTransform: 'none',
    backgroundColor: theme.palette.common.white,
    fontSize: 18,

    '&:hover': {
      backgroundColor: theme.palette.secondary.light,
      borderColor: theme.palette.secondary.main
    },

    [theme.breakpoints.up('md')]: {
      paddingTop: theme.spacing(2),
      paddingBottom: theme.spacing(2)
    }
  }
});
