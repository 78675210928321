
import React, { FormEvent, useRef, useState } from 'react';
import authService from '../../services/authentication';
import User from '../../models/User';
import useCurrentUser from '../../hooks/use-current-user';
import { useNotifier } from '../../providers/NotifierProvider';
import Box from '@material-ui/core/Box';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import IconButton from '@material-ui/core/IconButton';
import EditIcon from '@material-ui/icons/Edit';
import CircularProgress from '@material-ui/core/CircularProgress';
import PasswordUpdateForm, { PasswordUpdateFormRef, PasswordData } from '../../forms/PasswordUpdateForm';

const AccountInfoSection: React.FC = function() {
  const currentUser = useCurrentUser() as User;
  const passwordUpdateForm = useRef<PasswordUpdateFormRef>(null);
  const [newEmail, setNewEmail] = useState(currentUser.email);
  const notify = useNotifier();
  const [emailEditMode, setEmailEditMode] = useState(false);
  const [passwordEditMode, setPasswordEditMode] = useState(false);
  const [saving, setSaving] = useState(false);

  async function handleSaveEmail(ev: FormEvent) {
    try{
      ev.preventDefault();
      setSaving(true);
      await authService.updateCurrentUser({ email: newEmail });
      notify('Email updated.');
      setEmailEditMode(false);
    }
    catch(err){
      notify(err);
    }
    finally{
      setSaving(false);
    }
  }

  async function handlePasswordSave(data: PasswordData) {
    try{
      if(data.password.trim() !== data.confirm_password.trim())
        throw new Error('Password and confirm password fields do not match.');

      setSaving(true);
      await authService.updatePassword(data.password);
      setPasswordEditMode(false);
      notify('Profile updated.');
    }
    catch(err){
      notify(err);
    }
    finally{
      setSaving(false);
    }
  }

  return (
    <section>
      <Box p={2}>
        <Typography variant="h6">Account information</Typography>
        <Typography variant="subtitle2" color="textSecondary">View/Update account information</Typography>
      </Box>

      <Box p={2} mb={2}>
        <Grid container spacing={2}>
          <Grid item xs={12} sm={6} md={12} lg={6}>
            {!emailEditMode && (
              <Grid container spacing={2} alignItems="center">
                <Grid item>
                  <Typography variant="caption" color="textSecondary">EMAIL ADDRESS</Typography>
                  <Typography variant="body1">{ currentUser?.email }</Typography>
                </Grid>
                <Grid item>
                  <IconButton
                    onClick={()=>setEmailEditMode(true)}
                    size="small"
                  >
                    <EditIcon />
                  </IconButton>
                </Grid>
              </Grid>
            )}

            {emailEditMode && (
              <form onSubmit={handleSaveEmail}>
                <Grid container direction="column" spacing={2}>
                  <Grid item>
                    <TextField
                      type="email"
                      label="Email address"
                      variant="standard"
                      size="small"
                      value={newEmail}
                      onChange={(ev)=>setNewEmail(ev.target.value)}
                      disabled={saving}
                    />
                  </Grid>
                  <Grid item>
                    {!saving && (
                      <Grid container spacing={1}>
                        <Grid item>
                          <Button
                            size="small"
                            onClick={()=>setEmailEditMode(false)}
                            disabled={saving}
                          >Cancel</Button>
                        </Grid>
                        <Grid item>
                          <Button
                            type="submit"
                            color="primary"
                            variant="contained"
                            size="small"
                            disabled={saving}
                          >
                            Save
                          </Button>
                        </Grid>
                      </Grid>
                    )}
                    {saving && (
                      <CircularProgress color="primary" size={24} />
                    )}
                  </Grid>
                </Grid>
              </form>
            )}
          </Grid>
          <Grid item xs={12} sm={6} md={12} lg={6}>
            {!passwordEditMode && (
              <Grid container spacing={2} alignItems="center">
                <Grid item>
                  <Typography variant="caption" color="textSecondary">PASSWORD</Typography>
                  <Typography variant="body1">********</Typography>
                </Grid>
                <Grid item>
                  <IconButton
                    size="small"
                    onClick={()=>setPasswordEditMode(true)}
                  >
                    <EditIcon />
                  </IconButton>
                </Grid>
              </Grid>
            )}
            {passwordEditMode && (
              <PasswordUpdateForm
                ref={passwordUpdateForm}
                onSubmit={handlePasswordSave}
                disabled={saving}
              />
            )}

            {passwordEditMode && !saving && (
              <Box pt={2}>
                <Grid container spacing={1}>
                  <Grid item>
                    <Button
                      onClick={()=>setPasswordEditMode(false)}
                      size="small"
                    >Cancel</Button>
                  </Grid>
                  <Grid item>
                    <Button
                      size="small"
                      color="primary"
                      variant="contained"
                      onClick={()=>passwordUpdateForm.current?.submitForm()}
                    >Save</Button>
                  </Grid>
                </Grid>
              </Box>
            )}

            {passwordEditMode && saving && (
              <Box pt={2}>
                <CircularProgress color="primary" size={24} />
              </Box>
            )}
          </Grid>
        </Grid>
      </Box>
    </section>
  )
};

export default AccountInfoSection;
