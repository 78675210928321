
import axios from 'axios';
import { momentTrackService } from './moment-track';
import binLabelsTemplate from '../label-templates/bin-labels';
import binLabelsSheetTemplate from '../label-templates/bin-labels-sheet';
import locationLabelTemplate from '../label-templates/location-label';
import locationLabelSheetTemplate from '../label-templates/location-label-sheet';

export async function sendOrder(order: object) {
  const response = await axios.post('https://hooks.zapier.com/hooks/catch/1257677/oa5c5m1', order, {
    headers: {
      'Content-Type': 'text/plain'
    }
  });

  console.log(response);
}

// export async function generateBinLabels(product: any, binA: any, binB: any) {
//   const template = binLabelsTemplate({
//     type: 'reorder',
//     license_plate_url_bin_a: momentTrackService.generateUrl(binA.id, 'licence_plate'),
//     license_plate_url_bin_b: momentTrackService.generateUrl(binB.id, 'licence_plate'),
//     document_url_bin_a: momentTrackService.generateUrl(binA.id, 'document'),
//     document_url_bin_b: momentTrackService.generateUrl(binB.id, 'document'),
//     part_number: product.part_number,
//     part_description: product.name
//   });

//   const response = await axios.post('https://api.3hd.us/app/html2pdf.generate_pdf', {
//     html: template,
//     pdf_mode: 'portrait',
//     pdf_name: 'bin-labels-' + product.part_number,
//     page_size: 'A4'
//   });

//   return response.data.data?.s3_path;
// }


export function printPdf(pdfUrl: string) {
  return axios.post('https://k2vpki1p5i.execute-api.us-west-1.amazonaws.com/prod/printNode', {
    "printer_url": "3hd.us/b23JFAFladW8KeD5GxFX9jeTK",
    "title": "Small label",
    "contentType": "pdf_uri",
    "content": pdfUrl,
    "expireAfter": 600,
    "passkey": "jaredeggettiscool"
  })
}



export async function generateBinLabels(product: any, binA: any, binB: any, templateType: 'single' | 'sheet' = 'single') {
  let labelsList, requestData;
  labelsList = [
    {
      type: 'Bottom A - Reorder',
      url: momentTrackService.generateUrl(binA.id, 'document'),
      iconUrl: 'https://replenish-icons.s3-us-west-1.amazonaws.com/current_icons/document.svg',
      part_number: product.part_number,
      product_name: product.name
    },
    {
      type: 'Front A - Reorder',
      url: momentTrackService.generateUrl(binA.id, 'licence_plate'),
      iconUrl: 'https://replenish-icons.s3-us-west-1.amazonaws.com/current_icons/licence_plate_icon.svg',
      part_number: product.part_number,
      product_name: product.name
    },
    {
      type: 'Bottom B - Reorder',
      url: momentTrackService.generateUrl(binB.id, 'document'),
      iconUrl: 'https://replenish-icons.s3-us-west-1.amazonaws.com/current_icons/document.svg',
      part_number: product.part_number,
      product_name: product.name
    },
    {
      type: 'Front B - Reorder',
      url: momentTrackService.generateUrl(binB.id, 'licence_plate'),
      iconUrl: 'https://replenish-icons.s3-us-west-1.amazonaws.com/current_icons/licence_plate_icon.svg',
      part_number: product.part_number,
      product_name: product.name
    },
  ];

  if(templateType === 'single'){
    requestData = {
      html: binLabelsTemplate(labelsList),
      pdf_mode: 'portrait',
      pdf_name: 'bin-labels-' + product.part_number,
      page_width: '66.675mm',
      page_height: '25.4mm',
      margin_top: '0mm',
      margin_bottom: '0mm',
      margin_left: '0mm',
      margin_right: '0mm',
      disable_smart_shrinking: null
    };
  }
  else{
    requestData = {
      html: binLabelsSheetTemplate(labelsList),
      pdf_mode: 'portrait',
      pdf_name: 'bin-labels-' + product.part_number,
      page_size: 'Letter',
      margin_top: '0mm',
      margin_bottom: '0mm',
      margin_left: '0mm',
      margin_right: '0mm',
      disable_smart_shrinking: null
    };
  }

  const response = await axios.post('https://api.3hd.us/app/html2pdf.generate_pdf', requestData);
  await printPdf(response.data.data?.s3_path);
  return response.data.data?.s3_path;
}

export async function generateLocationLabel(location: any, templateType: 'single' | 'sheet' = 'single') {
  const labelData = {
    url: momentTrackService.generateUrl(location.id, 'location'),
    location_name: location.name,
    iconUrl: 'https://replenish-icons.s3-us-west-1.amazonaws.com/current_icons/location_icon.svg'
  }

  let requestData;

  if(templateType === 'single'){
    requestData = {
      html: locationLabelTemplate(labelData),
      pdf_mode: 'portrait',
      pdf_name: 'location-label-' + location.id + '-' + location.name.replace(/\s+/ig, '-'),
      page_width: '66.675mm',
      page_height: '25.4mm',
      margin_top: '0mm',
      margin_bottom: '0mm',
      margin_left: '0mm',
      margin_right: '0mm',
      disable_smart_shrinking: null
    }
  }
  else{
    requestData = {
      html: locationLabelSheetTemplate(labelData),
      pdf_mode: 'portrait',
      pdf_name: 'location-label-' + location.id + '-' + location.name.replace(/\s+/ig, '-'),
      page_size: 'Letter',
      margin_top: '0mm',
      margin_bottom: '0mm',
      margin_left: '0mm',
      margin_right: '0mm',
      disable_smart_shrinking: null
    }
  }

  const response = await axios.post('https://api.3hd.us/app/html2pdf.generate_pdf', requestData);

  await printPdf(response.data.data?.s3_path);
  return response.data.data?.s3_path;
}
