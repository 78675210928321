
import React, { forwardRef, useImperativeHandle, useMemo } from 'react';
import validationSchema from './validation-schema';
import { useFormik } from 'formik';
import useVendors from '../../hooks/use-vendors';
import useLocations from '../../hooks/use-locations';
import useProducts from '../../hooks/use-products';
import { BinFamilyFormProps, BinFamilyFormRef, BinFamilyData } from './types';
import Grid from '@material-ui/core/Grid';
import CircularProgress from '@material-ui/core/CircularProgress';
import TextField from '@material-ui/core/TextField';

const BinFamilyForm: React.ForwardRefRenderFunction<BinFamilyFormRef, BinFamilyFormProps> = function(props, ref) {
  const {
    initialValues = {},
    onSubmit = ()=>{},
    disabled

  } = props;

  const [locations, loadingLocations] = useLocations();
  const [vendors, loadingVendors] = useVendors();
  const [products, loadingProducts] = useProducts();

  const vendorOptions = useMemo(()=>{
    return vendors.map(v=>({ label: v.name, value: v.id }));
  }, [vendors]);

  const productOptions = useMemo(()=>{
    return products.map(p=>({ label: p.name, value: p.id }));
  }, [products]);

  const locationOptions = useMemo(()=>{
    return locations.map(l=>({ label: l.name, value: l.id }));
  }, [locations]);

  const formik = useFormik<BinFamilyData>({
    initialValues: {
      product_id: initialValues.product_id,
      default_quantity: initialValues.default_quantity,
      location_id: initialValues.location_id,
      preferred_vendor_id: initialValues.preferred_vendor_id,
    },
    validationSchema,
    onSubmit
  });

  useImperativeHandle(ref, ()=>formik, [formik]);

  return (
    <form onSubmit={formik.handleSubmit}>
      <Grid container spacing={2}>
        <Grid item xs={12} sm={6}>
          {loadingProducts && (<CircularProgress size={30} />)}
          {!loadingProducts && (
            <TextField
              variant="standard"
              label="PRODUCT"
              {...formik.getFieldProps('product_id')}
              error={formik.touched['product_id'] && !!formik.errors['product_id']}
              helperText={formik.errors['product_id']}
              disabled={disabled}
              select
              SelectProps={{ native: true }}
            >
              <option value=""></option>
              {productOptions.map(opt=>(
                <option key={opt.value + ''} value={opt.value}>{ opt.label }</option>
              ))}
            </TextField>
          )}
        </Grid>
        <Grid item xs={12} sm={6}>
          <TextField
            variant="standard"
            type="number"
            label="DEFAULT QTY"
            {...formik.getFieldProps('default_quantity')}
            value={formik.values['default_quantity'] || ''}
            error={formik.touched['default_quantity'] && !!formik.errors['default_quantity']}
            helperText={formik.errors['default_quantity']}
            disabled={disabled}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          {loadingLocations && (<CircularProgress size={30} />)}
          {!loadingLocations && (
            <TextField
              variant="standard"
              label="LOCATION"
              {...formik.getFieldProps('location_id')}
              error={formik.touched['location_id'] && !!formik.errors['location_id']}
              helperText={formik.errors['location_id']}
              disabled={disabled}
              select
              SelectProps={{ native: true }}
            >
              <option value=""></option>
              {locationOptions.map(opt=>(
                <option key={opt.value + ''} value={opt.value}>{ opt.label }</option>
              ))}
            </TextField>
          )}
        </Grid>
        <Grid item xs={12} sm={6}>
          {loadingVendors && (<CircularProgress size={30} />)}
          {!loadingVendors && (
            <TextField
              variant="standard"
              label="VENDOR"
              {...formik.getFieldProps('preferred_vendor_id')}
              error={formik.touched['preferred_vendor_id'] && !!formik.errors['preferred_vendor_id']}
              helperText={formik.errors['preferred_vendor_id']}
              disabled={disabled}
              select
              SelectProps={{ native: true }}
            >
              <option value=""></option>
              {vendorOptions.map(opt=>(
                <option key={opt.value + ''} value={opt.value}>{ opt.label }</option>
              ))}
            </TextField>
          )}
        </Grid>
      </Grid>
    </form>
  );
};

export default forwardRef(BinFamilyForm);
