
import React, { useRef } from 'react';
import { productsService } from '../../services/api';
import { useNotifier } from '../../providers/NotifierProvider';
import useProducts from '../../hooks/use-products';
import Container from '@material-ui/core/Container';
import Box from '@material-ui/core/Box';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import Fab from '@material-ui/core/Fab';
import AddIcon from '@material-ui/icons/Add';
import LinearProgress from '@material-ui/core/LinearProgress';
import Typography from '@material-ui/core/Typography';
import InfoMessage from '../../components/InfoMessage';
import ProductDialog, { ProductDialogRef, ProductData } from '../../dialogs/ProductDialog';
import ProductCard from './ProductCard';

const ProductsPage: React.FC = function() {
  const [products, loading, message] = useProducts();
  const productDialog = useRef<ProductDialogRef>(null);
  const notify = useNotifier();

  async function handleProductCreate(data: ProductData) {
    await productsService.create(data);
    notify('Product created.');
  }

  return (
    <Container maxWidth={false}>
      <Box py={2}>
        <Paper>
          <Box p={2}>
            <Grid container spacing={2} justify="space-between" alignItems="center">
              <Grid item>
                <Typography variant="h6">Products</Typography>
                <Typography variant="subtitle2" color="textSecondary">View/Update products</Typography>
              </Grid>
              <Grid item>
                <Fab
                  size="medium"
                  color="primary"
                  disabled={loading}
                  onClick={()=>productDialog.current?.openDialog(true)}
                >
                  <AddIcon />
                </Fab>
              </Grid>
            </Grid>
          </Box>
          {loading && (
            <LinearProgress />
          )}
          {!loading && !!message && (
            <Box p={2}>
              <InfoMessage>{message}</InfoMessage>
            </Box>
          )}
        </Paper>

        <Box py={2}>
          <Grid container spacing={1}>
            {products.map(product=>(
              <Grid key={''+product.id} item xs={12} sm={4} md={3}>
                <ProductCard
                  product={product}
                />
              </Grid>
            ))}
          </Grid>
        </Box>
      </Box>

      <ProductDialog
        ref={productDialog}
        primaryActionText="Create"
        secondaryActionText="Cancel"
        primaryAction={handleProductCreate}
      />
    </Container>
  );
};

export default ProductsPage;
