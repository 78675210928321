
import React, { useState } from 'react';
import * as orderTrackService from '../../services/order-track';
import { RouteComponentProps } from 'react-router-dom';
import { useLocation } from '../../hooks/use-locations';
import { useConfirmDialog } from '../../providers/ConfirmDialogProvider';
import { useNotifier } from '../../providers/NotifierProvider';
import MenuButton from '../../components/MenuButton';
import InfoMessage from '../../components/InfoMessage';
import Container from '@material-ui/core/Container';
import Box from '@material-ui/core/Box';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import Divider from '@material-ui/core/Divider';
import LinearProgress from '@material-ui/core/LinearProgress';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import PdfIcon from '@material-ui/icons/PictureAsPdf';
import OpenInNewIcon from '@material-ui/icons/OpenInNew';

const LocationPage: React.FC<RouteComponentProps<{locationId: string}>> = function(props) {
  const { match } = props;
  const locationId = Number(match.params.locationId);
  const confirm = useConfirmDialog();
  const notify = useNotifier();
  const [labelDocumentUrl, setLabelDocumentUrl] = useState('');
  const [location, loading, message] = useLocation(locationId);
  const [generatingLabel, setGeneratingLabel] = useState(false);

  async function handleGenerateLabel(templateType: 'single' | 'sheet') {
    try{
      if(!(await confirm('Continue?', 'Generating pdf document for label.')))
        return;

      notify('Generating label...');
        
      setGeneratingLabel(true);
      const labelPdf = await orderTrackService.generateLocationLabel(location, templateType);
      setLabelDocumentUrl(labelPdf);

      notify('Label generated.');
      // window.open(labelPdf, '_blank');
    }
    catch(err){
      notify(err);
    }
    finally{
      setGeneratingLabel(false);
    }
  }

  return (
    <Container maxWidth={false}>
      <Box py={2}>
        <Paper>
          <Box p={2}>
            <Typography variant="h6">{loading || !location ? '...' : location.name}</Typography>
            <Typography variant="subtitle2" color="textSecondary">Location</Typography>
          </Box>
          <Divider />
          {loading && (
            <LinearProgress />
          )}

          {!loading && !!message && (
            <Box p={2}>
              <InfoMessage>{ message }</InfoMessage>
            </Box>
          )}

          {!!location && (
            <Box p={2}>
              <Grid container spacing={3}>
                <Grid item xs={6} sm={4} lg={3}>
                  <Typography variant="caption" color="textSecondary">PARENT LOCATION</Typography>
                  <Typography variant="body1">{ location.parent_location_id || '--' }</Typography>
                </Grid>
              </Grid>
            </Box>
          )}
        </Paper>
      </Box>

      <Box py={2}>
        <MenuButton
          color="primary"
          size="large"
          startIcon={<PdfIcon />}
          disabled={generatingLabel || loading}
          onClick={()=>handleGenerateLabel('single')}
          menuItems={[
            {
              label: 'Multiple labels (8.5" x 11" 30 labels sheet)',
              onClick: ()=>handleGenerateLabel('sheet')
            },
            {
              label: 'Single label (2 5/8" x 1" roll)',
              onClick: ()=>handleGenerateLabel('single')
            }
          ]}
        >
          Generate labels
        </MenuButton>
      </Box>
      {!!labelDocumentUrl && (
        <Box pb={2}>
          <Button
            color="primary"
            disabled={loading || generatingLabel}
            startIcon={<OpenInNewIcon />}
            size="large"
            onClick={()=>window.open(labelDocumentUrl, '_blank')}
          >
            Open label document
          </Button>
        </Box>
      )}
    </Container>
  );
}

export default LocationPage;
