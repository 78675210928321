
import { useState, useEffect, useCallback } from 'react';
import { productsService } from '../../services/api';
import Product from '../../models/Product';
import { useNotifier } from '../../providers/NotifierProvider';

export default function useProduct(productId: number): [Product|null, boolean, string] {
  const [product, setProduct] = useState<Product | null>(null);
  const [loading, setLoading] = useState(false);
  const [message, setMessage] = useState('');
  const notify = useNotifier();

  const fetchProduct = useCallback(async ()=>{
    try{
      setLoading(true);
      const product = await productsService.fetchById(productId);
      setProduct(product);
      setMessage('');
    }
    catch(err){
      setMessage('Failed to fetch product.');
      notify(err);
    }
    finally{
      setLoading(false);
    }
  }, [productId, notify]);

  useEffect(()=>{
    fetchProduct();
  }, [fetchProduct]);

  return [product, loading, message];
}
