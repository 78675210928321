
export default class Vendor {
  id: number;
  name: string;
  organization_id: number;
  erp_number: string;
  active: boolean;
  created_at: string;

  constructor(data: {[key: string]: any}) {
    data = data || {};
    
    this.id = data.id;
    this.name = data.name || '';
    this.organization_id = data.organization_id;
    this.erp_number = data.erp_number;
    this.active = data.active;
    this.created_at = data.created_at;
  }
}
