
import { createStyles, Theme } from '@material-ui/core/styles';

export default (theme: Theme) => createStyles({
  root: {
    minHeight: '100vh',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center'
  },
  formContainer: {
    marginBottom: theme.spacing(2)
  },
  formHeader: {
    textAlign: 'center',
    marginBottom: theme.spacing(6)
  },
  forgotLink: {
    fontWeight: 400
  },
  submitBtn: {
    marginTop: theme.spacing(4)
  }
});
