
export default class Store {
  constructor(
    private storage = localStorage,
    private prefix = 'store::'
  ) {}

  get<T>(key: string): T {
    return JSON.parse(this.storage.getItem(this.prefix + key) as string);
  }
  
  set<T>(key: string, value: T): boolean {
    try {
      // Avoid accidently setting null and undefined values as strings "null" and "undefined".
      if(value === null || value === undefined){
        this.remove(this.prefix + key);
        return true;
      }
      this.storage.setItem(this.prefix + key, JSON.stringify(value));
      return true;
    } catch (e) {
      return false;
    }
  }
  
  remove(key: string) {
    return this.storage.removeItem(this.prefix + key);
  }
  
  clear() {
    return this.storage.clear();
  }
  
  backend(storage: Storage): Storage {
    storage && (this.storage = storage);
  
    return this.storage;
  }
}