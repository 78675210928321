
import { useState, useEffect, useCallback } from 'react';
import { useNotifier } from '../../providers/NotifierProvider';
import { shoppingListsService } from '../../services/shopping-lists';
import ShoppingList from '../../models/ShoppingList';

export default function useShoppingLists(): [ShoppingList[], boolean, Error|string] {
  const [shoppingLists, setShoppingLists] = useState<ShoppingList[]>([]);
  const [message, setMessage] = useState<Error | string>('');
  const [loading, setLoading] = useState(true);
  const notify = useNotifier();

  const fetchShoppingLists = useCallback(async ()=>{
    try{
      setLoading(true);
      const shoppingLists = await shoppingListsService.fetchAll();
      setMessage(shoppingLists.length === 0 ? 'Nothing found.' : '');
      setShoppingLists(shoppingLists);
    }
    catch(err){
      notify(err);
      setMessage('Failed to load shoppingLists.');
    }
    finally{
      setLoading(false);
    }
  }, [notify]);

  useEffect(()=>{
    fetchShoppingLists();

  }, [ fetchShoppingLists ]);

  useEffect(()=>{
    function addShoppingList(shoppingList: ShoppingList){
      setShoppingLists(shoppingLists=>([...shoppingLists, shoppingList]));
      setMessage('');
    }

    function removeShoppingList(shoppingListId: number) {
      setShoppingLists(shoppingLists=>shoppingLists.filter(v=>v.id !== shoppingListId));
    }

    function updateShoppingList(updatedShoppingList: ShoppingList, shoppingListId: number) {
      setShoppingLists(shoppingLists=>{
        const index = shoppingLists.findIndex(p=>p.id === shoppingListId);
        const updatedList = [...shoppingLists];
        updatedList[index] = updatedShoppingList;
        return updatedList;
      });
    }

    shoppingListsService.on('shopping-list-created', addShoppingList);
    shoppingListsService.on('shopping-list-deleted', removeShoppingList);
    shoppingListsService.on('shopping-list-updated', updateShoppingList);

    return ()=>{
      shoppingListsService.off('shopping-list-created', addShoppingList);
      shoppingListsService.off('shopping-list-deleted', removeShoppingList);
      shoppingListsService.off('shopping-list-updated', updateShoppingList);
    }

  }, []);

  return [shoppingLists, loading, message];
}
