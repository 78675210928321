
import React, { useState, useRef } from 'react';
import { vendorsService } from '../../services/api';
import { useConfirmDialog } from '../../providers/ConfirmDialogProvider';
import { useNotifier } from '../../providers/NotifierProvider';
import { VendorCardProps } from './types';
import VendorDialog, { VendorDialogRef, VendorData } from '../../dialogs/VendorDialog';
import Box from '@material-ui/core/Box';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import IconButton from '@material-ui/core/IconButton';
import Typography from '@material-ui/core/Typography';
import CircularProgress from '@material-ui/core/CircularProgress';
import MoreHorizIcon from '@material-ui/icons/MoreHoriz';
// import OpenInNewIcon from '@material-ui/icons/OpenInNew';
import InfoIcon from '@material-ui/icons/InfoOutlined';
import EditIcon from '@material-ui/icons/Edit';
import DeleteIcon from '@material-ui/icons/Delete';

const VendorCard: React.FC<VendorCardProps> = function(props) {
  const { vendor } = props;
  const confirm = useConfirmDialog();
  const notify = useNotifier();
  const vendorDialog = useRef<VendorDialogRef>(null);
  const [menuAnchorEl, setMenuAnchorEl] = useState<HTMLElement | null>(null);
  const [loading, setLoading] = useState(false);

  function handleVendorEdit() {
    setMenuAnchorEl(null);
    vendorDialog.current?.openDialog(true);
  }

  async function handleVendorSave(data: VendorData) {
    await vendorsService.updateById(vendor.id, data);
    notify('Vendor updated.');
  }

  async function handleVendorDelete() {
    try{
      setMenuAnchorEl(null);

      if(await confirm('Are you sure?', `You are about to delete vendor "${vendor.name}". This action cannot be undone.`)){
        setLoading(true);
        await vendorsService.deleteById(vendor.id);
        notify('Vendor deleted.');
      }
      else
        return;
    }
    catch(err){
      notify(err);
    }
    finally{
      setLoading(false);
    }
  }

  return (
    <Paper>
      <Box p={2}>
        <Grid container spacing={1} alignItems="center" wrap="nowrap">
          <Grid item xs zeroMinWidth>
            <Typography variant="body1" noWrap>{ vendor.name }</Typography>
          </Grid>
          <Grid item>
            <IconButton
              disabled={loading}
              size="small"
              onClick={()=>vendorDialog.current?.openDialog()}
            >
              <InfoIcon />
            </IconButton>
          </Grid>
          {!loading && (
            <Grid item>
              <IconButton
                disabled={loading}
                size="small"
                edge="end"
                onClick={ev=>setMenuAnchorEl(ev.currentTarget)}
              >
                <MoreHorizIcon />
              </IconButton>
            </Grid>
          )}
          {loading && (
            <Grid item style={{ lineHeight: 0 }}>
              <CircularProgress size={30} />
            </Grid>
          )}
        </Grid>
      </Box>

      <Menu
        anchorEl={menuAnchorEl}
        open={Boolean(menuAnchorEl)}
        onClose={()=>setMenuAnchorEl(null)}
      >
        {/* <MenuItem>
          <ListItemIcon><OpenInNewIcon fontSize="small" /></ListItemIcon>
          <ListItemText>Open</ListItemText>
        </MenuItem> */}
        <MenuItem onClick={handleVendorEdit}>
          <ListItemIcon><EditIcon fontSize="small" /></ListItemIcon>
          <ListItemText>Edit</ListItemText>
        </MenuItem>
        <MenuItem onClick={handleVendorDelete}>
          <ListItemIcon><DeleteIcon fontSize="small" /></ListItemIcon>
          <ListItemText>Delete</ListItemText>
        </MenuItem>
      </Menu>

      <VendorDialog
        ref={vendorDialog}
        vendor={vendor}
        primaryActionText="Update"
        secondaryActionText="Close"
        primaryAction={handleVendorSave}
      />
    </Paper>
  );
};

export default VendorCard;
