
import React, { useImperativeHandle, useState, forwardRef } from 'react';
import moment from 'moment';
import { momentTrackService } from '../../services/moment-track';
import { ShoppingListDialogProps, ShoppingListDialogRef } from './types';
import Box from '@material-ui/core/Box';
import Grid from '@material-ui/core/Grid';
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import DialogActions from '@material-ui/core/DialogActions';
import Divider from '@material-ui/core/Divider';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';

const ShoppingListDialog: React.ForwardRefRenderFunction<ShoppingListDialogRef, ShoppingListDialogProps> = function(props, ref) {
  const { shoppingList } = props;
  const [open, setOpen] = useState(false);

  useImperativeHandle(ref, ()=>{
    function openDialog() {
      setOpen(true);
    }

    return { openDialog };
  }, []);

  return (
    <Dialog
      open={open}
      fullWidth
      maxWidth="md"
      onClose={()=>setOpen(false)}
    >
      <DialogTitle>Shopping list</DialogTitle>
      <Divider />
      <DialogContent>
        <Box pt={2} pb={4}>
          <Grid container spacing={3}>
            <Grid item xs={12} sm={4}>
              <Typography variant="caption" color="textSecondary">CREATED AT</Typography>
              <Typography variant="body1" noWrap>{moment(shoppingList.created_at).format('MMM DD - hh:mma')}</Typography>
            </Grid>

            <Grid item xs={6} sm={4}>
              <Typography variant="caption" color="textSecondary">PRODUCT</Typography>
              <Typography variant="body1" noWrap>{shoppingList.product.name}</Typography>
            </Grid>

            <Grid item xs={6} sm={4}>
              <Typography variant="caption" color="textSecondary">PART #</Typography>
              <Typography variant="body1" noWrap>{shoppingList.product.part_number}</Typography>
            </Grid>

            <Grid item xs={6} sm={4}>
              <Typography variant="caption" color="textSecondary">VENDOR PART #</Typography>
              <Typography variant="body1" noWrap>{shoppingList.product.preferred_vendor_part_number}</Typography>
            </Grid>

            <Grid item xs={6} sm={4}>
              <Typography variant="caption" color="textSecondary">VENDOR</Typography>
              <Typography variant="body1" noWrap>{shoppingList.vendor.name}</Typography>
            </Grid>

            <Grid item xs={6} sm={4}>
              <Typography variant="caption" color="textSecondary">ERP #</Typography>
              <Typography variant="body1" noWrap>{shoppingList.vendor.erp_number}</Typography>
            </Grid>

            <Grid item xs={6} sm={4}>
              <Typography variant="caption" color="textSecondary">LOCATION</Typography>
              <Typography variant="body1" noWrap>{shoppingList.location.name}</Typography>
            </Grid>

            <Grid item xs={6} sm={4}>
              <Typography variant="caption" color="textSecondary">BIN FAMILY ID</Typography>
              <Typography variant="body1" noWrap>{shoppingList.bin.bin_family_id}</Typography>
            </Grid>

            <Grid item xs={6} sm={4}>
              <Typography variant="caption" color="textSecondary">BIN ID</Typography>
              <Typography variant="body1" noWrap>{shoppingList.bin_id}</Typography>
            </Grid>

            <Grid item xs={12} sm={6}>
              <Typography variant="caption" color="textSecondary">DOCUMENT</Typography>
              <Typography variant="body1" noWrap>{momentTrackService.generateUrl(shoppingList.bin_id, 'document')}</Typography>
            </Grid>

            <Grid item xs={12} sm={6}>
              <Typography variant="caption" color="textSecondary">LICENCE PLATE</Typography>
              <Typography variant="body1" noWrap>{momentTrackService.generateUrl(shoppingList.bin_id, 'licence_plate')}</Typography>
            </Grid>

            <Grid item xs={12} sm={6}>
              <Typography variant="caption" color="textSecondary">ACTIVITY</Typography>
              <Typography variant="body1" noWrap>{momentTrackService.generateUrl(shoppingList.activity_id, 'activity')}</Typography>
            </Grid>
          </Grid>
        </Box>
      </DialogContent>
      <DialogActions>
        <Button onClick={()=>setOpen(false)}>Close</Button>
      </DialogActions>
    </Dialog>
  );
};

export default forwardRef(ShoppingListDialog);
