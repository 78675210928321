
import Store from './Store';
import EventEmitter from 'events';
import User from '../../models/User';

export default class AuthenticationStore extends EventEmitter {
  private store: Store;

  private access_token: string | null;
  private refresh_token: string | null;
  private org_slug: string | null;
  private user: User | null;

  private TOKEN_KEY = 'access_token';
  private REFRESH_TOKEN_KEY = 'refresh_token';
  private USER_KEY = 'user';
  private ORG_KEY = 'org_slug';

  constructor() {
    super();
    this.store = new Store(undefined, 'authstore::');

    this.access_token = this.store.get(this.TOKEN_KEY);
    this.refresh_token = this.store.get(this.REFRESH_TOKEN_KEY);
    this.org_slug = this.store.get(this.ORG_KEY);
    this.user = new User(this.store.get(this.USER_KEY));
  }

  get(key: string) {
    switch(key){
      case 'access_token':
        return this.access_token;
      case 'refresh_token':
        return this.refresh_token;
      case 'org_slug':
        return this.org_slug || 'default';
      case 'user':
        return this.user;
      default:
        return null;
    }
  }

  set(key: string, value: any) {
    switch(key){
      case 'access_token':
        this.access_token = value;
        break;
      default:
        return;
    }
  }

  saveToken(token: string) {
    if(token){
      this.store.set(this.TOKEN_KEY, token);
      this.access_token = token;
      this.emit('access_token', token);
    }
  }

  saveUser(user: User) {
    if(user){
      this.store.set(this.USER_KEY, user);
      this.user = user;
      this.emit('user', user);
    }
  }

  saveRefreshToken(token: string) {
    if(token){
      this.store.set(this.REFRESH_TOKEN_KEY, token);
      this.refresh_token = token;
      this.emit('refresh_token', token);
    }
  }

  saveOrgSlug(org_slug: string) {
    if(org_slug){
      this.store.set(this.ORG_KEY, org_slug);
      this.org_slug = org_slug;
      this.emit('org_slug', org_slug);
    }
  }

  clearAll() {
    this.store.remove(this.TOKEN_KEY);
    this.store.remove(this.USER_KEY);
    this.store.remove(this.REFRESH_TOKEN_KEY);
    this.store.remove(this.ORG_KEY);

    this.access_token = this.refresh_token = this.org_slug = this.user = null;
    this.emit('clear');
  }
}
