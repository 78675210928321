
import React, { useState, useRef } from 'react';
import { MenuButtonProps, MenuItemModel } from './types';
import Button from '@material-ui/core/Button';
import IconButton from '@material-ui/core/IconButton';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';

const MenuButton: React.FC<MenuButtonProps> = function(props) {
  const {
    menuItems,
    color,
    size,
    onClick,
    disabled,
    ...buttonProps
  } = props;

  const [open, setOpen] = useState(false);
  const anchorEl = useRef(null);

  function handleMenuClick(menuItem: MenuItemModel) {
    return () => {
      setOpen(false);
      menuItem.onClick && menuItem.onClick();
    };
  }

  return (
    <>
      <Button
        ref={anchorEl}
        onClick={onClick || (()=>setOpen(true))}
        color={color}
        size={size}
        disabled={disabled}
        {...buttonProps}
      />
      <IconButton
        color={color}
        size={size === 'large' ? 'medium' : size}
        onClick={()=>setOpen(true)}
        disabled={disabled}
      >
        <ArrowDropDownIcon />
      </IconButton>

      <Menu open={open} onClose={()=>setOpen(false)} anchorEl={anchorEl.current}>
        {menuItems.map((menuItem, i)=>(
          <MenuItem key={''+i} onClick={handleMenuClick(menuItem)}>
            {menuItem.label}
          </MenuItem>
        ))}
      </Menu>
    </>
  );
};

export default MenuButton;
