
import React, { useImperativeHandle, forwardRef } from 'react';
import { PasswordUpdateFormProps, PasswordUpdateFormRef } from './types';
import { useFormik } from 'formik';
import Grid from '@material-ui/core/Grid';
import TextField from '@material-ui/core/TextField';

const PasswordUpdateForm: React.ForwardRefRenderFunction<PasswordUpdateFormRef, PasswordUpdateFormProps> = function(props, ref) {
  const {
    disabled = false,
    onSubmit = ()=>{}
  } = props;

  const formik = useFormik({
    initialValues: {
      password: '',
      confirm_password: ''
    },
    onSubmit
  });

  useImperativeHandle(ref, ()=>formik, [formik]);

  return (
    <form onSubmit={formik.handleSubmit}>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <TextField
            type="password"
            label="Password"
            {...formik.getFieldProps('password')}
            disabled={disabled}
            variant="standard"
            size="small"
          />
        </Grid>
        <Grid item xs={12}>
          <TextField
            type="password"
            label="Confirm password"
            {...formik.getFieldProps('confirm_password')}
            disabled={disabled}
            variant="standard"
            size="small"
          />
        </Grid>
      </Grid>
    </form>
  );
};

export default forwardRef(PasswordUpdateForm);
