
import ApiService from './ApiService';
import User from '../../models/User';
import Product from '../../models/Product';
import Bin from '../../models/Bin';
import Vendor from '../../models/Vendor';
import Location from '../../models/Location';
import Printer from '../../models/Printer';
import { authenticationStore } from '../store';

export { default } from './ApiService';

export const usersService = new ApiService<User>(
  ()=>`/api/${authenticationStore.get('org_slug')}/users`,
  'user',
  data=>new User(data)
);

export const locationsService = new ApiService<Location>(
  ()=>`/api/${authenticationStore.get('org_slug')}/locations`,
  'location',
  data=>new Location(data)
);

export const printersService = new ApiService<Printer>(
  ()=>`/api/${authenticationStore.get('org_slug')}/printers`,
  'printer',
  data=>new Printer(data)
);

export const vendorsService = new ApiService<Vendor>(
  ()=>`/api/${authenticationStore.get('org_slug')}/vendors`,
  'vendor',
  data=>new Vendor(data)
);

export const productsService = new ApiService<Product>(
  ()=>`/api/${authenticationStore.get('org_slug')}/products`,
  'product',
  data=>new Product(data)
);

export const binsService = new ApiService<Bin>(
  ()=>`/api/${authenticationStore.get('org_slug')}/bins`,
  'bin',
  data=>new Bin(data)
);
