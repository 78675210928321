
import { useState, useEffect, useCallback } from 'react';
import { useNotifier } from '../../providers/NotifierProvider';
import { binsService } from '../../services/api';
import Bin from '../../models/Bin';

export default function useBins(binFamilyId: number): [Bin[], boolean, Error|string] {
  const [bins, setBins] = useState<Bin[]>([]);
  const [message, setMessage] = useState<Error | string>('');
  const [loading, setLoading] = useState(true);
  const notify = useNotifier();

  const fetchBins = useCallback(async (binFamilyId?: number)=>{
    try{
      setLoading(true);
      const bins = await binsService.fetchAll();
      const filteredBins = bins.filter((bin)=>bin.bin_family_id === binFamilyId);
      setMessage(filteredBins.length === 0 ? 'Nothing found.' : '');
      setBins(filteredBins);
    }
    catch(err){
      notify(err);
      setMessage('Failed to load bins.');
    }
    finally{
      setLoading(false);
    }
  }, [notify]);

  useEffect(()=>{
    fetchBins(binFamilyId);

  }, [ fetchBins, binFamilyId ]);

  useEffect(()=>{
    function addBin(bin: Bin){
      setBins(bins=>([...bins, bin]));
      setMessage('');
    }

    function removeBin(binId: number) {
      setBins(bins=>bins.filter(v=>v.id !== binId));
    }

    function updateBin(updatedBin: Bin, binId: number) {
      setBins(bins=>{
        const index = bins.findIndex(l=>l.id === binId);
        const updatedList = [...bins];
        updatedList[index] = updatedBin;
        return updatedList;
      });
    }

    binsService.on('bin-created', addBin);
    binsService.on('bin-deleted', removeBin);
    binsService.on('bin-updated', updateBin);

    return ()=>{
      binsService.off('bin-created', addBin);
      binsService.off('bin-deleted', removeBin);
      binsService.off('bin-updated', updateBin);
    }

  }, []);

  return [bins, loading, message];
}
