
import axios from 'axios';
import { authenticationStore } from '../store';

const API_HOST = process.env.REACT_APP_API_HOST;

const apiClient = axios.create({
  baseURL: API_HOST,
  withCredentials: true
});

apiClient.interceptors.request.use((config)=>{
  config.headers['Authorization'] = 'Bearer ' + authenticationStore.get('access_token');
  return config;
});

apiClient.interceptors.response.use(undefined, async function(err){
  try{
    if(err.isAxiosError && err.response && err.response.status === 401){
      const refreshToken = authenticationStore.get('refresh_token');
      if(!refreshToken)
        throw err;
        
      const response = await axios.post(`${API_HOST}/auth/default/system/refresh`, undefined, {
        headers: {
          'Authorization': 'Bearer ' + refreshToken
        }
      });

      const data = response.data;
      authenticationStore.saveToken(data.access_token);

      return await axios.request({
        ...err.config,
        headers: {
          'Authorization': 'Bearer ' + data.access_token
        }
      });
    }
    else
      throw err;
  }
  catch(err){
    return Promise.reject(err);
  }
})

apiClient.interceptors.response.use(response=>{
  const actualData = response.data.data;
  response.data = actualData || response.data.message || 'Success!';
  return response;
}, err=>{
  if(err.isAxiosError && err.response)
    return Promise.reject(new Error(err.response.data.message || err.message));
  else
    return Promise.reject(err);
});

export default apiClient;
