
import React from 'react';
import Container from '@material-ui/core/Container';
import Box from '@material-ui/core/Box';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import ProfileInfoSection from './ProfileInfoSection';
import AccountInfoSection from './AccountInfoSection';

const SettingsPage: React.FC = function() {
  return (
    <Container maxWidth={false}>
      <Box py={2}>
        <Grid container spacing={2}>
          <Grid item xs={12} md={6}>
            <Paper>
              <ProfileInfoSection />
            </Paper>
          </Grid>
          <Grid item xs={12} md={6}>
            <Paper>
              <AccountInfoSection />
            </Paper>
          </Grid>
        </Grid>
      </Box>
    </Container>
  );
};

export default SettingsPage;
