
import Product from './Product';
import Vendor from './Vendor';
import Location from './Location';

export default class BinFamily {
  id: number;
  product_id: number;
  product: Product;
  default_quantity: number;
  location_id: number;
  location: Location;
  preferred_vendor_id: number;
  preferred_vendor: Vendor;
  created_at: string;
  organization_id: number;
  active: boolean;

  constructor(data: {[key: string]: any}) {
    data = data || {};

    this.id = data.id;
    this.product_id = data.product_id;
    this.product = new Product(data.product);
    this.default_quantity = data.default_quantity || 0;
    this.location_id = data.location_id;
    this.location = new Location(data.location);
    this.preferred_vendor_id = data.preferred_vendor_id;
    this.preferred_vendor = new Vendor(data.preferred_vendor);
    this.created_at = data.created_at;
    this.organization_id = data.organization_id;
    this.active = data.active;
  }
}
