
import { ButtonProps } from '@material-ui/core/Button';

export enum CameraMode {
  USER = 'user',
  ENVIRONMENT = 'environment'
}

export interface QrCodeScannerProps {
  title?: string;
  buttonText?: string;
  facingMode?: CameraMode;
  onResult?: (data: string)=>void;
  disabled?: boolean;
  hidden?: boolean;
  ButtonProps?: ButtonProps;
}

export interface QrCodeScannerRef {
  openScanner: ()=>void;
}
